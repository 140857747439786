import React from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

const StandardSidebarAd = ({
  properties: { header, headerType, headerDisplayStyle, subline, teaser, image },
}) => {
  return (
    <div className="sidebar-ad">
      <Preline preline={subline} />

      <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

      {image && (
        <div className="mt-xsmall">
          <Image image={image} />
        </div>
      )}

      {teaser && (
        <div className="mt-xsmall">
          <Text textType="html" text={teaser} />
        </div>
      )}
    </div>
  );
};

StandardSidebarAd.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardSidebarAd;
