import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import DividerSwitcher from "../Divider/DividerSwitcher";

// eslint-disable-next-line import/no-cycle
import ElementSwitcher from "../ElementSwitcher";

import RelatedNewsList from "./RelatedNewsList";

import { ReactComponent as ChevronLeft } from "../../../Images/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../Images/svg/chevron-right.svg";

import TranslationContext from "../../../Context/Translation";

const { REACT_APP_SITE_TITLE, REACT_APP_PUBLIC_URL } = process.env;

const SidebarNewsDetails = ({
  properties: {
    details,
    contentElements,
    prevArticle,
    nextArticle,
    relatedArticles,
    hideDefaultHeader,
    hideDate,
    sidebar,
    animation,
  },
}) => {
  const inlineStyles = {
    backgroundImage: `url(${details.media?.[0]?.images.detailViewImage.publicUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "50%",
  };

  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderElements = () => {
    if (contentElements) {
      return <ElementSwitcher contentElements={contentElements} />;
    }

    return <></>;
  };

  const renderMetaTags = () => {
    return (
      <MetaTags>
        {details.metaData.alternativeTitle ? (
          <title>{details.metaData.alternativeTitle}</title>
        ) : (
          <title>{details.title}</title>
        )}

        {details.metaData.description ? (
          <meta name="description" content={details.metaData.description} />
        ) : (
          <meta name="description" content={details.bodytext} />
        )}

        {details.metaData.keywords ? (
          <meta name="keywords" content={details.metaData.keywords} />
        ) : null}

        {REACT_APP_SITE_TITLE && <meta property="og:site_name" content={REACT_APP_SITE_TITLE} />}

        {details.metaData.alternativeTitle ? (
          <meta property="og:title" content={details.metaData.alternativeTitle} />
        ) : (
          <meta property="og:title" content={details.title} />
        )}

        {details.metaData.description ? (
          <meta property="og:description" content={details.metaData.description} />
        ) : (
          <meta property="og:description" content={details.bodytext} />
        )}

        {details.media?.[0] && (
          <meta property="og:image" content={details.media[0].images.detailViewImage.publicUrl} />
        )}

        <meta property="og:url" content={REACT_APP_PUBLIC_URL + details.canonical} />

        <meta name="twitter:card" content="summary_large_image" />

        {details.metaData.alternativeTitle ? (
          <meta name="twitter:title" content={details.metaData.alternativeTitle} />
        ) : (
          <meta name="twitter:title" content={details.title} />
        )}

        {details.metaData.description ? (
          <meta name="twitter:description" content={details.metaData.description} />
        ) : (
          <meta name="twitter:description" content={details.bodytext} />
        )}

        {details.media?.[0] && (
          <meta name="twitter:image" content={details.media?.[0]?.images.defaultImage.publicUrl} />
        )}

        <meta name="twitter:url" content={REACT_APP_PUBLIC_URL + details.canonical} />

        {details.media?.[0] && (
          <meta name="vk:image" content={details.media?.[0]?.images.detailViewImage.publicUrl} />
        )}
      </MetaTags>
    );
  };

  useEffect(() => {
    // dirty hack to change the last item of the breadcrumbs to article's title
    const activeBreadcrumb = document.querySelector(".breadcrumb-item.active");
    const newsDetailsTitle = document.querySelector(".news-details-title");

    if (activeBreadcrumb && newsDetailsTitle) {
      activeBreadcrumb.textContent = newsDetailsTitle.textContent;
    }
  }, []);

  return (
    <div className="news-details">
      {renderMetaTags()}

      <div className="container sidebar">
        <div className="row">
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">
                {!hideDefaultHeader && (
                  <div className="container mb-small">
                    {(details.datetime || details.title) && (
                      <div className="row justify-content-center mb-xsmall">
                        <div className="col">
                          <Headline
                            headerType="h2"
                            headline={details.title}
                            additionalClasses="news-details-title"
                          />

                          {!hideDate && <Preline preline={details.datetime} />}
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col">
                        <Image
                          imageType="background"
                          image={details.media?.[0]}
                          style={inlineStyles}
                        />
                      </div>
                    </div>

                    {details.bodytext && (
                      <div className="row justify-content-center mt-small">
                        <div className="col">
                          <Text
                            textType="html"
                            text={details.bodytext}
                            additionalClasses="news-details-teaser"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {contentElements?.length > 0 && renderElements()}
              </div>
            </div>

            {relatedArticles.length > 0 && (
              <div className="mt-small d-none d-lg-block">
                <div className="mb-small">
                  <Headline
                    headerType="h2"
                    header={
                      activeTranslation.twoLetterIsoCode === "de"
                        ? "Ähnliche Beiträge"
                        : "Similar Posts"
                    }
                  />
                </div>

                <RelatedNewsList
                  relatedArticles={relatedArticles}
                  hideDate={hideDate}
                  animation={animation}
                />
              </div>
            )}
          </div>

          <div className="col-lg-3">
            <aside className="news-details-sidebar mt-4 mt-lg-0">
              <div className="news-details-categories mb-4">
                <div className="card">
                  <div className="card-body">
                    <Headline
                      headerType="h3"
                      headline={
                        activeTranslation.twoLetterIsoCode === "de" ? "Kategorien" : "Categories"
                      }
                    />

                    <div className="mt-xsmall">
                      <ul className="news-details-categories-list">
                        {details.categories.map((category) => (
                          <li key={category.title} className="news-details-categories-item">
                            <NavLink className="news-details-categories-link" to={category.url}>
                              {category.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="news-details-ad">
                <div className="card">
                  <div className="card-body">
                    <ElementSwitcher contentElements={sidebar} />
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>

      {relatedArticles.length > 0 && (
        <div className="container mt-small mb-small d-block d-lg-none">
          <div className="mb-small">
            <Headline
              headerType="h2"
              header={
                activeTranslation.twoLetterIsoCode === "de" ? "Ähnliche Beiträge" : "Similar Posts"
              }
            />
          </div>

          <RelatedNewsList
            relatedArticles={relatedArticles}
            hideDate={hideDate}
            animation={animation}
          />
        </div>
      )}

      {(prevArticle?.url || nextArticle?.url) && (
        <div className="container mb-xlarge">
          <DividerSwitcher layout="standard" />

          <div className="pagination">
            <div className="page-item">
              {prevArticle.url && (
                <>
                  <a href={prevArticle.url} title={prevArticle.title}>
                    <ChevronLeft />

                    <span>{prevArticle.title}</span>
                  </a>
                </>
              )}
            </div>

            <div className="page-item">
              {nextArticle.url && (
                <>
                  <a href={nextArticle.url} title={nextArticle.title}>
                    <span>{nextArticle.title}</span>

                    <ChevronRight />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SidebarNewsDetails.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SidebarNewsDetails;
