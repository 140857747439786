import React from "react";
import PropTypes from "prop-types";

import StandardInformationSlider from "./StandardInformationSlider";
import ComponentMissing from "../../Debug/ComponentMissing";

const InformationSliderSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  description,
  startValue,
  maxValue,
  thresholds,
  unitType,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    description,
    startValue,
    maxValue,
    thresholds,
    unitType,
    animation,
  };

  function switchInformationSlider() {
    switch (layout) {
      case "standard":
        return <StandardInformationSlider properties={properties} />;
      default:
        return <ComponentMissing type="InformationSlider" subType={layout} />;
    }
  }

  return switchInformationSlider();
};

InformationSliderSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  description: PropTypes.string,
  startValue: PropTypes.number,
  maxValue: PropTypes.number,
  thresholds: PropTypes.instanceOf(Array),
  unitType: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default InformationSliderSwitcher;
