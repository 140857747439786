import React from "react";
import PropTypes from "prop-types";

import OnPageMenuSection from "./OnPageMenuSection";
import TopNavigationMenuSection from "./TopNavigationMenuSection";
import VerticalListMenuSection from "./VerticalListMenuSection";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuSectionSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  let properties;

  properties = {
    navigation: navigation[0].content,
  };

  if (layout === "topNavigation") {
    properties = {
      primaryLink,
      primaryLinkType,
      secondaryLink,
      secondaryLinkType,
      logo,
      topLinks,
      navigation: navigation[0].content,
      languages,
      activeLanguage,
    };
  }

  function switchMenuSection() {
    switch (layout) {
      case "onPage":
        return <OnPageMenuSection properties={properties} />;
      case "topNavigation":
        return <TopNavigationMenuSection properties={properties} />;
      case "vertical-list":
        return <VerticalListMenuSection properties={properties} />;
      default:
        return <ComponentMissing type="MenuSection" subType={layout} />;
    }
  }

  return switchMenuSection();
};

MenuSectionSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuSectionSwitcher;
