import React from "react";
import PropTypes from "prop-types";

import StandardBuyFondsModal from "./StandardBuyFondsModal";
import ComponentMissing from "../../Debug/ComponentMissing";

const BuyFondsModal = ({ layout, sectionId, fonds }) => {
  const properties = {
    sectionId,
    fonds,
  };

  function switchBuyFondsModal() {
    switch (layout) {
      case "standard":
        return <StandardBuyFondsModal properties={properties} />;
      default:
        return <ComponentMissing type="BuyFondsModal" subType={layout} />;
    }
  }

  return switchBuyFondsModal();
};

BuyFondsModal.propTypes = {
  layout: PropTypes.string,
  sectionId: PropTypes.string,
  fonds: PropTypes.instanceOf(Array),
};

export default BuyFondsModal;
