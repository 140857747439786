import React from "react";
import PropTypes from "prop-types";

import StandardPerformanceChart from "./StandardPerformanceChart";
import ComponentMissing from "../../Debug/ComponentMissing";

const PerformanceChartSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  bottomTeaser,
  fonds,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    bottomTeaser,
    fonds,
    animation,
  };

  function switchPerformanceChart() {
    switch (layout) {
      case "standard":
        return <StandardPerformanceChart properties={properties} />;
      default:
        return <ComponentMissing type="PerformanceChart" subType={layout} />;
    }
  }

  return switchPerformanceChart();
};

PerformanceChartSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  bottomTeaser: PropTypes.string,
  fonds: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default PerformanceChartSwitcher;
