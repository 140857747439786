import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Image from "../../Atoms/Image";
import Text from "../../Atoms/Text";

const IconCardWallTAILItem = ({ headline, teaser, image }) => {
  return (
    <div className="col">
      <div className="card">
        <div className="card-body">
          {(image || headline) && (
            <div className="mb-small">
              <div className="card-image">
                {image && (
                  <div className="me-4">
                    <Image image={image} />
                  </div>
                )}

                <Headline headerType="h4" headline={headline} additionalClasses="color-static" />
              </div>
            </div>
          )}

          <Text textType="html" text={teaser} additionalClasses="color-static" />
        </div>
      </div>
    </div>
  );
};

IconCardWallTAILItem.propTypes = {
  headline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  length: PropTypes.number,
};

export default IconCardWallTAILItem;
