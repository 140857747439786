import React from "react";
import PropTypes from "prop-types";

const Preline = ({ preline, additionalClasses }) => {
  return preline ? <h6 className={`preline ${additionalClasses}`.trim()}>{preline}</h6> : <></>;
};

Preline.propTypes = {
  preline: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Preline.defaultProps = {
  additionalClasses: "",
};

export default Preline;
