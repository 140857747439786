import React from "react";
import PropTypes from "prop-types";

const SecondaryTable = ({ properties: { table, additionalClasses } }) => {
  return (
    <div className={`table-responsive ${additionalClasses}`.trim()}>
      <table className="table table-hover">
        <thead>
          <tr>
            {table[0].map((tableHeading) => (
              <th key={`key_${tableHeading || Math.random()}`} scope="col">
                {tableHeading}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {table.slice(1).map((tableRows, index) => (
            <tr key={`key_${index || Math.random()}`}>
              {tableRows.map((tableCell, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <th key={`key_${tableCell || Math.random()}`} scope="row">
                      {tableCell}
                    </th>
                  );
                }

                return <td key={`key_${Math.random()}`}>{tableCell}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SecondaryTable.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryTable;
