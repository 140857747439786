import React from "react";
import PropTypes from "prop-types";

import StandardCustomCode from "./StandardCustomCode";
import ComponentMissing from "../../Debug/ComponentMissing";

const CustomCodeSwitcher = ({ layout, code }) => {
  const properties = {
    code,
  };

  function switchCustomCode() {
    switch (layout) {
      case "standard":
        return <StandardCustomCode properties={properties} />;
      default:
        return <ComponentMissing type="CustomCode" subType={layout} />;
    }
  }

  return switchCustomCode();
};

CustomCodeSwitcher.propTypes = {
  layout: PropTypes.string,
  code: PropTypes.string,
};

export default CustomCodeSwitcher;
