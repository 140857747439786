import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm";
import "@fancyapps/ui/dist/fancybox.css";

const Fancybox = ({ delegate, options, children }) => {
  const delegateString = delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = options || {};

    NativeFancybox.bind(delegateString, opts);
    NativeFancybox.defaults.Hash = false;
    NativeFancybox.defaults.placeFocusBack = false;

    return () => {
      NativeFancybox.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

Fancybox.propTypes = {
  delegate: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};

export default Fancybox;
