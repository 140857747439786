/* eslint-disable guard-for-in */
/* eslint-disable new-cap */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4geodataWorldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodataLangEN from "@amcharts/amcharts4-geodata/lang/EN";
import am4geodataLangDE from "@amcharts/amcharts4-geodata/lang/DE";
import * as am4pluginsRangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";
import * as Charts from "../Constants/ChartConstants";

export function cookColor(colorSet, colorScale, baseColorHex) {
  const colorArray = [];

  for (let i = 0; i < colorScale; i += 1) {
    const opacity = 1 - i * 0.1;
    const str = `rgba(${baseColorHex}, ${opacity})`;
    colorArray.push(str);
  }

  return colorArray;
}

export function getRandomColor() {
  return Charts.chartColors[Math.floor(Math.random() * Charts.chartColors.length)];
}

export function createPieChart(chartDiv, legendDiv, data, pickedColor) {
  // create a piechart
  const chart = am4core.create(chartDiv, am4charts.PieChart);
  // set data

  chart.data = data;

  // create new legend, set options
  const legendContainer = am4core.create(legendDiv, am4core.Container);
  chart.legend = new am4charts.Legend();
  chart.legend.valueLabels.template.align = "left";
  chart.legend.valueLabels.template.textAlign = "start";
  chart.numberFormatter.numberFormat = "#";
  chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#')}%";
  legendContainer.width = am4core.percent(100);
  legendContainer.height = am4core.percent(100);
  // add legend to chart
  chart.legend.parent = legendContainer;

  // define styling
  const pieSeries = chart.series.push(new am4charts.PieSeries());
  const colorSet = new am4core.ColorSet();
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name";
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  colorSet.list = cookColor(colorSet, chart.data.length, Charts.baseColorHex[pickedColor]).map(
    (color) => {
      return new am4core.color(color);
    },
  );
  pieSeries.colors = colorSet;
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.slices.template.cursorOverStyle = [
    {
      property: "cursor",
      value: "pointer",
    },
  ];
  pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.#')}%";

  // dynamically resize legend container
  function resizeLegend() {
    document.getElementById(legendDiv).style.height = `${chart.legend.contentHeight}px`;
  }

  chart.events.on("datavalidated", resizeLegend);
  chart.events.on("maxsizechanged", resizeLegend);

  chart.legend.events.on("datavalidated", resizeLegend);
  chart.legend.events.on("maxsizechanged", resizeLegend);

  // return chart
  return chart;
}

export function createDonutChart(chartDiv, legendDiv, data, pickedColor) {
  // create a piechart
  const chart = am4core.create(chartDiv, am4charts.PieChart);
  // set data

  chart.data = data;

  // create new legend, set options
  const legendContainer = am4core.create(legendDiv, am4core.Container);
  chart.legend = new am4charts.Legend();
  chart.legend.valueLabels.template.align = "left";
  chart.legend.valueLabels.template.textAlign = "start";
  chart.numberFormatter.numberFormat = "#";
  chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#')}%";
  legendContainer.width = am4core.percent(100);
  legendContainer.height = am4core.percent(100);
  // add legend to chart
  chart.legend.parent = legendContainer;

  // cut a hole in the pie
  chart.innerRadius = am4core.percent(30);

  // define styling
  const pieSeries = chart.series.push(new am4charts.PieSeries());
  const colorSet = new am4core.ColorSet();
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name";
  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  colorSet.list = cookColor(colorSet, chart.data.length, Charts.baseColorHex[pickedColor]).map(
    (color) => {
      return new am4core.color(color);
    },
  );
  pieSeries.colors = colorSet;
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.slices.template.cursorOverStyle = [
    {
      property: "cursor",
      value: "pointer",
    },
  ];
  pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.#')}%";

  // dynamically resize legend container
  function resizeLegend() {
    document.getElementById(legendDiv).style.height = `${chart.legend.contentHeight}px`;
  }

  chart.events.on("datavalidated", resizeLegend);
  chart.events.on("maxsizechanged", resizeLegend);

  chart.legend.events.on("datavalidated", resizeLegend);
  chart.legend.events.on("maxsizechanged", resizeLegend);

  // return chart
  return chart;
}

export function createPerformanceChart(targetDiv, performance, controlsDiv, language = "en") {
  /* eslint-disable no-param-reassign */
  function customizeGrip(grip) {
    grip.icon.disabled = true;
    grip.width = 23;
    grip.height = 23;
    grip.background.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.background.states.getKey("hover").properties.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.background.states.getKey("down").properties.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.strokeWidth = 0;
  }
  /* eslint-enable no-param-reassign */

  /* eslint-disable no-param-reassign */
  function customizeThumb(thumb) {
    thumb.background.fill = am4core.color(Charts.COLOR_SECONDARY_DISABLED);
    thumb.background.fillOpacity = 0.75;
    thumb.background.states.getKey("hover").properties.fill = am4core.color(
      Charts.COLOR_SECONDARY_DISABLED,
    );
    thumb.background.states.getKey("hover").properties.fillOpacity = 0.9;
    thumb.background.states.getKey("down").properties.fill = am4core.color(
      Charts.COLOR_SECONDARY_DISABLED,
    );
    thumb.background.states.getKey("down").properties.fillOpacity = 0.9;
    thumb.strokeWidth = 0;
  }
  /* eslint-enable no-param-reassign */

  const x = am4core.create(targetDiv, am4charts.XYChart);
  x.background.fill = "#ffffff";
  x.background.fillOpacity = 0; // changes for background_color_second
  x.data = performance.data;

  const dateAxis = x.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0.5;
  dateAxis.renderer.minGridDistance = 50;
  dateAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  dateAxis.renderer.labels.template.fontSize = 12;
  dateAxis.renderer.labels.template.fontFamily = "MuseoSans";
  dateAxis.renderer.labels.template.fontWeight = 300;

  if (controlsDiv) {
    const selector = new am4pluginsRangeSelector.DateAxisRangeSelector();

    selector.container = document.getElementById(controlsDiv);
    selector.axis = dateAxis;

    const buttonsArrayDE = [
      { name: "Seit Auflage", interval: "max" },
      { name: "5 Jahre", interval: { timeUnit: "year", count: 5 } },
      { name: "3 Jahre", interval: { timeUnit: "year", count: 3 } },
      { name: "1 Jahr", interval: { timeUnit: "year", count: 1 } },
      { name: "3 Monate", interval: { timeUnit: "month", count: 3 } },
      { name: "1 Monat", interval: { timeUnit: "month", count: 1 } },
      { name: "1 Woche", interval: { timeUnit: "week", count: 1 } },
    ];
    const buttonsArrayEN = [
      { name: "All time", interval: "max" },
      { name: "5 Years", interval: { timeUnit: "year", count: 5 } },
      { name: "3 Years", interval: { timeUnit: "year", count: 3 } },
      { name: "1 Year", interval: { timeUnit: "year", count: 1 } },
      { name: "3 Months", interval: { timeUnit: "month", count: 3 } },
      { name: "1 Month", interval: { timeUnit: "month", count: 1 } },
      { name: "1 Week", interval: { timeUnit: "week", count: 1 } },
    ];

    selector.periods = language === "de" ? buttonsArrayDE : buttonsArrayEN;

    x.events.on("datavalidated", () => {
      const buttonsElements = document.querySelectorAll(".amcharts-range-selector-period-button");

      [...buttonsElements].forEach((buttonElement) => {
        buttonElement.addEventListener("click", (e) => {
          [...buttonsElements].forEach((button) => {
            button.classList.remove("active");
          });

          e.target.classList.add("active");
        });
      });
    });
  }

  // Create vertical scrollbar and place it before the value axis
  x.scrollbarY = new am4core.Scrollbar();
  x.scrollbarY.parent = x.leftAxesContainer;
  x.scrollbarY.background.fill = am4core.color(Charts.COLOR_LIGHT);
  x.scrollbarY.background.fillOpacity = 0.9;
  customizeThumb(x.scrollbarY.thumb);
  customizeGrip(x.scrollbarY.startGrip);
  customizeGrip(x.scrollbarY.endGrip);

  const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  valueAxis.renderer.labels.template.fontSize = 12;
  valueAxis.renderer.labels.template.fontFamily = "MuseoSans";
  valueAxis.renderer.labels.template.fontWeight = 300;
  // valueAxis.renderer.labels.template.adapter.add("text", (text, label) => {
  //   return label.dataItem.value !== undefined ? `${label.dataItem.value.toFixed(1)} %` : null;
  // });
  // valueAxis.title.text = "Wertentwicklung kumuliert (in %)";

  const scrollbarX = new am4charts.XYChartScrollbar();
  x.scrollbarX = scrollbarX;
  x.scrollbarX.parent = x.bottomAxesContainer;
  x.scrollbarX.unselectedOverlay.fill = am4core.color(Charts.COLOR_LIGHT);
  x.scrollbarX.unselectedOverlay.fillOpacity = 0.75;
  customizeThumb(x.scrollbarX.thumb);
  customizeGrip(x.scrollbarX.startGrip);
  customizeGrip(x.scrollbarX.endGrip);

  performance.fonds.forEach((item) => {
    const series = x.series.push(new am4charts.LineSeries());
    series.name = item.name;
    series.strokeWidth = 2;
    series.fill = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.stroke = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.dataFields.valueY = item.isin;
    series.dataFields.dateX = "date";
    series.tooltipText = "{valueY}";
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color("#FFFFFF");

    // series.tooltip.getFillFromObject = false;
    series.tooltip.background.cornerRadius = 6;
    series.tooltip.background.strokeOpacity = 0;

    // [1].forEach((dataEntry, index) => {
    //   if (index % 96 === 0) {
    //     const bullet = series.bullets.push(new am4charts.CircleBullet());

    //     bullet.circle.fill = am4core.color("#494953");
    //     bullet.circle.stroke = am4core.color("#fff");
    //     bullet.circle.strokeWidth = 2;
    //   }
    // });

    scrollbarX.series.push(series);
  });

  x.scrollbarX.scrollbarChart.plotContainer.filters.clear();

  x.scrollbarX.scrollbarChart.series.values.forEach(
    // eslint-disable-next-line no-param-reassign
    (series) => (series.stroke = am4core.color("#000000")),
  );

  x.numberFormatter.numberFormat = {
    style: "decimal",
    minimumFractionDigits: 2,
  };

  x.legend = new am4charts.Legend();

  x.cursor = new am4charts.XYCursor();

  // Enable export
  // x.exporting.menu = new am4core.ExportMenu();
  // x.exporting.menu.items = [
  //   {
  //     label: "",
  //     menu: [
  //       { type: "png", label: "Download" },
  //       { label: "Drucken", type: "print" },
  //     ],
  //   },
  // ];
  // x.exporting.menu.verticalAlign = "bottom";
  // // x.exporting.menu.align = 'left';

  return x;
}

export function createMapChart(targetDiv, countries = [], language = "en") {
  let locale;

  switch (language) {
    case "en":
      locale = am4geodataLangEN;
      break;
    case "de":
      locale = am4geodataLangDE;
      break;
    default:
      locale = am4geodataLangEN;
  }

  const map = am4core.create(targetDiv, am4maps.MapChart);
  map.projection = new am4maps.projections.Miller();
  map.geodataNames = locale;

  const countriesID = countries.map((country) => country.countryId);

  // Create map polygon series for world map
  const worldSeries = map.series.push(new am4maps.MapPolygonSeries());
  worldSeries.useGeodata = true;
  worldSeries.geodata = am4geodataWorldLow.default;
  worldSeries.exclude = ["AQ", ...countriesID];

  const worldPolygon = worldSeries.mapPolygons.template;
  // worldPolygon.nonScalingStroke = true;
  worldPolygon.strokeOpacity = 0.5;
  worldPolygon.fill = am4core.color("#d4d4d0");

  const countrySeries = map.series.push(new am4maps.MapPolygonSeries());
  // series.name = group.name;
  countrySeries.useGeodata = true;
  countrySeries.geodata = am4geodataWorldLow.default;
  countrySeries.include = countriesID;

  const countryPolygon = countrySeries.mapPolygons.template;
  // countryPolygon.nonScalingStroke = true;
  countryPolygon.strokeOpacity = 1;
  countryPolygon.fill = am4core.color(Charts.COLOR_PRIMARY);
  countryPolygon.tooltipText = "{name}";

  countrySeries.tooltip.getFillFromObject = false;
  countrySeries.tooltip.background.fill = am4core.color("#ffffff");
  countrySeries.tooltip.background.fillOpacity = 1;
  countrySeries.tooltip.label.fill = am4core.color(Charts.COLOR_SECONDARY);

  const hs = countryPolygon.states.create("hover");
  hs.properties.fill = am4core.color(Charts.COLOR_PRIMARY_HOVER);

  return map;
}

export function createInvestmentChart(chartDiv, data, language = "en") {
  // Create chart instance
  const chart = am4core.create(chartDiv, am4charts.XYChart);
  chart.background.fill = "#ffffff";
  chart.background.fillOpacity = 0; // changes for background_color_second
  chart.maskBullets = false;
  chart.data = data;

  // Create axes
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0.5;
  dateAxis.renderer.minGridDistance = 50;
  dateAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  dateAxis.renderer.labels.template.fontSize = 12;
  dateAxis.renderer.labels.template.fontFamily = "MuseoSans";
  dateAxis.renderer.labels.template.fontWeight = 300;
  dateAxis.startLocation = 0.5;
  dateAxis.endLocation = 0.5;

  dateAxis.dateFormats.setKey("month", "MMM");
  dateAxis.periodChangeDateFormats.setKey("month", "MMM");

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  valueAxis.renderer.labels.template.fontSize = 12;
  valueAxis.renderer.labels.template.fontFamily = "MuseoSans";
  valueAxis.renderer.labels.template.fontWeight = 300;

  // Create series
  function createSeries(field, name, color) {
    const series = chart.series.push(new am4charts.LineSeries());
    series.name = name;
    series.strokeWidth = 2;
    series.fill = am4core.color(color);
    series.stroke = am4core.color(color);
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";

    // series.tooltipText = "{dateX}: [b]{valueY}[/]";
    series.tooltipText = "{valueY.formatNumber('#.##')}";
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color("#FFFFFF");

    series.smoothing = "monotoneX";

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.fill = am4core.color("#494953");
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 2;

    return series;
  }

  const paymentLabel = language === "en" ? "Investment" : "Anlagesumme";
  const capitalLabel = language === "en" ? "Accretion" : "Wertzuwachs";

  createSeries("payed", paymentLabel, Charts.COLOR_PRIMARY);
  createSeries("capital", capitalLabel, Charts.COLOR_SECONDARY);

  chart.legend = new am4charts.Legend();
  chart.cursor = new am4charts.XYCursor();

  return chart;
}

export function createLineChart(targetDiv, lines) {
  /* eslint-disable no-param-reassign */
  function customizeGrip(grip) {
    grip.icon.disabled = true;
    grip.width = 23;
    grip.height = 23;
    grip.background.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.background.states.getKey("hover").properties.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.background.states.getKey("down").properties.fill = am4core.color(Charts.COLOR_SECONDARY);
    grip.strokeWidth = 0;
  }
  /* eslint-enable no-param-reassign */

  /* eslint-disable no-param-reassign */
  function customizeThumb(thumb) {
    thumb.background.fill = am4core.color(Charts.COLOR_SECONDARY_DISABLED);
    thumb.background.fillOpacity = 0.75;
    thumb.background.states.getKey("hover").properties.fill = am4core.color(
      Charts.COLOR_SECONDARY_DISABLED,
    );
    thumb.background.states.getKey("hover").properties.fillOpacity = 0.9;
    thumb.background.states.getKey("down").properties.fill = am4core.color(
      Charts.COLOR_SECONDARY_DISABLED,
    );
    thumb.background.states.getKey("down").properties.fillOpacity = 0.9;
    thumb.strokeWidth = 0;
  }
  /* eslint-enable no-param-reassign */

  const x = am4core.create(targetDiv, am4charts.XYChart);
  x.background.fill = "#ffffff";
  x.background.fillOpacity = 0; // changes for background_color_second
  x.data = lines.linesInfo.data;

  const dateAxis = x.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0.5;
  dateAxis.renderer.minGridDistance = 50;
  dateAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  dateAxis.renderer.labels.template.fontSize = 12;
  dateAxis.renderer.labels.template.fontFamily = "MuseoSans";
  dateAxis.renderer.labels.template.fontWeight = 300;

  // Create vertical scrollbar and place it before the value axis
  x.scrollbarY = new am4core.Scrollbar();
  x.scrollbarY.parent = x.leftAxesContainer;
  x.scrollbarY.background.fill = am4core.color(Charts.COLOR_LIGHT);
  x.scrollbarY.background.fillOpacity = 0.9;
  customizeThumb(x.scrollbarY.thumb);
  customizeGrip(x.scrollbarY.startGrip);
  customizeGrip(x.scrollbarY.endGrip);

  const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.labels.template.fill = am4core.color(Charts.COLOR_TERTIARY);
  valueAxis.renderer.labels.template.fontSize = 12;
  valueAxis.renderer.labels.template.fontFamily = "MuseoSans";
  valueAxis.renderer.labels.template.fontWeight = 300;
  // valueAxis.renderer.labels.template.adapter.add("text", (text, label) => {
  //   return label.dataItem.value !== undefined ? `${label.dataItem.value.toFixed(1)} %` : null;
  // });
  // valueAxis.title.text = "Wertentwicklung kumuliert (in %)";

  const scrollbarX = new am4charts.XYChartScrollbar();
  x.scrollbarX = scrollbarX;
  x.scrollbarX.parent = x.bottomAxesContainer;
  x.scrollbarX.unselectedOverlay.fill = am4core.color(Charts.COLOR_LIGHT);
  x.scrollbarX.unselectedOverlay.fillOpacity = 0.75;
  customizeThumb(x.scrollbarX.thumb);
  customizeGrip(x.scrollbarX.startGrip);
  customizeGrip(x.scrollbarX.endGrip);

  lines.linesInfo.info.forEach((item) => {
    const series = x.series.push(new am4charts.LineSeries());
    series.name = item.name;
    series.strokeWidth = 2;
    series.fill = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.stroke = am4core.color(`rgba(${Charts.baseColorHex[item.color]}, 1)`);
    series.dataFields.valueY = item.uid;
    series.dataFields.dateX = "valueX";
    series.tooltipText = "{valueY}";
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color("#FFFFFF");

    // series.tooltip.getFillFromObject = false;
    series.tooltip.background.cornerRadius = 6;
    series.tooltip.background.strokeOpacity = 0;

    [1].forEach(() => {
      const bullet = series.bullets.push(new am4charts.CircleBullet());

      bullet.circle.fill = am4core.color("#494953");
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 2;
    });

    scrollbarX.series.push(series);
  });

  x.scrollbarX.scrollbarChart.plotContainer.filters.clear();

  x.scrollbarX.scrollbarChart.series.values.forEach(
    // eslint-disable-next-line no-param-reassign
    (series) => (series.stroke = am4core.color("#000000")),
  );

  x.numberFormatter.numberFormat = {
    style: "decimal",
    minimumFractionDigits: 2,
  };

  x.legend = new am4charts.Legend();

  x.cursor = new am4charts.XYCursor();

  // Enable export
  // x.exporting.menu = new am4core.ExportMenu();
  // x.exporting.menu.items = [
  //   {
  //     label: "",
  //     menu: [
  //       { type: "png", label: "Download" },
  //       { label: "Drucken", type: "print" },
  //     ],
  //   },
  // ];
  // x.exporting.menu.verticalAlign = "bottom";
  // // x.exporting.menu.align = 'left';

  return x;
}
