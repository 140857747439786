import React from "react";
import PropTypes from "prop-types";

import NewsListLayout from "./NewsListLayout";
import NewsDetailsLayout from "./NewsDetailsLayout";

const NewsLayout = ({ content, pageInfo }) => {
  const renderNewsLayout = () => {
    if (content.colPos0) {
      if (content.colPos0[0].content.data.settings.action === "list") {
        return <NewsListLayout content={content} pageInfo={pageInfo} />;
      }

      return <NewsDetailsLayout content={content} pageInfo={pageInfo} />;
    }

    return <></>;
  };

  return renderNewsLayout();
};

NewsLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
  pageInfo: PropTypes.instanceOf(Object),
};

export default NewsLayout;
