import React from "react";
import PropTypes from "prop-types";

const TextArea = ({
  id,
  label,
  formData,
  validationErrors,
  onChangeHandler,
  placeholder,
  additionalClasses,
}) => {
  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={id} className="form-label" dangerouslySetInnerHTML={{ __html: label }} />
      )}

      <textarea
        id={id}
        name={id}
        placeholder={placeholder}
        value={formData[id]}
        onChange={onChangeHandler}
        className={`form-control color-static ${validationErrors && "is-invalid"}`.trim()}
        rows="10"
      />
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.string,
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  additionalClasses: PropTypes.string,
};

TextArea.defaultProps = {
  additionalClasses: "",
};

export default TextArea;
