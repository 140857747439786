import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import StandardAccordionAndCategories from "./StandardAccordionAndCategories";
import ComponentMissing from "../../Debug/ComponentMissing";

const AccordionAndCategoriesSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  tabContent,
  items,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    tabContent,
    items,
  };

  function switchAccordionAndCategories() {
    switch (layout) {
      case "standard":
        return <StandardAccordionAndCategories properties={properties} />;
      default:
        return <ComponentMissing type="AccordionAndCategories" subType={layout} />;
    }
  }

  return switchAccordionAndCategories();
};

AccordionAndCategoriesSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  tabContent: PropTypes.instanceOf(Array),
  items: PropTypes.instanceOf(Object),
};

export default AccordionAndCategoriesSwitcher;
