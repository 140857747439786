import React from "react";
import PropTypes from "prop-types";

const Image = ({ imageType, image, style, additionalClasses, animation }) => {
  const renderImage = () => {
    if (imageType === "background") {
      return (
        <div
          className={`img img-background ${additionalClasses}`.trim()}
          style={style}
          data-aos={animation}
        />
      );
    }

    return (
      <img
        className={`img img-fluid ${additionalClasses}`.trim()}
        style={style}
        src={image.publicUrl}
        alt={image.properties.alternative}
        data-aos={animation}
      />
    );
  };

  return image ? renderImage() : <></>;
};

Image.propTypes = {
  imageType: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  additionalClasses: PropTypes.string,
  animation: PropTypes.string,
};

Image.defaultProps = {
  additionalClasses: "",
};

export default Image;
