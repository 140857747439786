import React from "react";
import PropTypes from "prop-types";

const Hidden = ({ id, defaultValue }) => {
  return <input id={id} type="hidden" name={id} defaultValue={defaultValue} />;
};

Hidden.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Hidden;
