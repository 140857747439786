import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import FormElementSwitcher from "./FormElementSwitcher";

const GridRow = ({
  formData,
  validationErrors,
  onChangeHandler,
  elements,
  additionalClasses,
  toggleableFields,
}) => {
  const getClasses = (item) => {
    const viewPorts = item.properties.gridColumnClassAutoConfiguration?.viewPorts;

    if (viewPorts) {
      return `col-xs-${viewPorts.xs.numbersOfColumnsToUse} 
              col-sm-${viewPorts.sm.numbersOfColumnsToUse} 
              col-md-${viewPorts.md.numbersOfColumnsToUse} 
              col-lg-${viewPorts.lg.numbersOfColumnsToUse}
              ${additionalClasses}`.replace(/\n\s+/g, "");
    }

    return `col-12 ${additionalClasses}`.trim();
  };

  return elements.map((item) => {
    return (
      <div key={`key_${item.identifier || Math.random()}`} className={getClasses(item)}>
        <FormElementSwitcher
          item={item}
          formData={formData}
          validationErrors={validationErrors}
          onChangeHandler={onChangeHandler}
          toggleableFields={toggleableFields}
        />
      </div>
    );
  });
};

GridRow.propTypes = {
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.instanceOf(Object),
  onChangeHandler: PropTypes.func,
  elements: PropTypes.instanceOf(Array),
  additionalClasses: PropTypes.string,
  toggleableFields: PropTypes.instanceOf(Array),
};

GridRow.defaultProps = {
  additionalClasses: "",
};

export default GridRow;
