import React from "react";
import PropTypes from "prop-types";

import StandardProductCards from "./StandardProductCards";
import SimpleProductCards from "./SimpleProductCards";
import ComponentMissing from "../../Debug/ComponentMissing";

const ProductCardsSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  subline,
  teaser,
  productCards,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    subline,
    teaser,
    productCards,
    animation,
  };

  function switchProductCards() {
    switch (layout) {
      case "standard":
        return <StandardProductCards properties={properties} />;
      case "simple":
        return <SimpleProductCards properties={properties} />;
      default:
        return <ComponentMissing type="ProductCard" subType={layout} />;
    }
  }

  return switchProductCards();
};

ProductCardsSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  subline: PropTypes.string,
  teaser: PropTypes.string,
  cards: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default ProductCardsSwitcher;
