import React, { useContext } from "react";
import PropTypes from "prop-types";

import RelatedNewsListItem from "./RelatedNewsListItem";

import TranslationContext from "../../../Context/Translation";

const RelatedNewsList = ({ relatedArticles, hideDate, animation }) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  return (
    <div className="news-related">
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
        {relatedArticles.map((relatedArticle) => {
          return (
            <RelatedNewsListItem
              news={relatedArticle}
              key={`key_${relatedArticle.title || Math.random()}`}
              linkType={relatedArticle.linkType}
              linkTitle={
                relatedArticle.linkTitle ||
                (activeTranslation.twoLetterIsoCode === "de" ? "Weiterlesen" : "Read More")
              }
              hideDate={hideDate}
              animation={animation}
            />
          );
        })}
      </div>
    </div>
  );
};

RelatedNewsList.propTypes = {
  relatedArticles: PropTypes.instanceOf(Array),
  hideDate: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default RelatedNewsList;
