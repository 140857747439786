import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import { Modal } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardSearchList = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    searchConfiguration,
    searchResults,
    emptySearchConfig,
    animation,
  },
}) => {
  const onRestartSearch = useCallback((e) => {
    e.preventDefault();

    const searchElement = document.getElementById("search");
    const search = Modal.getInstance(searchElement);

    search.show();
  }, []);

  const renderSearchListResults = useCallback(() => {
    if (searchResults.length > 0) {
      return searchResults.map((searchResult) => {
        return (
          <div key={searchResult.title} className="col d-flex">
            <div className="card w-100">
              <div className="card-body">
                <NavLink to={searchResult.slug}>
                  <Headline
                    headerType="h4"
                    headline={searchResult.title}
                    additionalClasses="color-static"
                  />
                </NavLink>

                <Text
                  textType="html"
                  text={searchResult.excerpt}
                  additionalClasses="color-static"
                />

                <div className="mt-large">
                  <div className="links">
                    <LinkSwitcher
                      linkType="link-secondary"
                      link={{ url: searchResult.slug, title: "Mehr anzeigen" }}
                      additionalClasses="color-static"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="col d-flex">
        <div className="card w-100">
          <div className="card-body">
            <Headline
              headerType="h4"
              headline={emptySearchConfig.message}
              additionalClasses="color-static"
            />

            <div className="mt-small">
              <div className="links">
                <LinkSwitcher
                  linkType="btn-primary"
                  link={{ url: "/", title: emptySearchConfig.reopenSearchLabel }}
                  // additionalClasses="btn-lg rounded-pill color-static"
                  additionalClasses="color-static"
                  onClick={onRestartSearch}
                />

                <LinkSwitcher
                  linkType="link-secondary"
                  link={emptySearchConfig.additionalLink}
                  additionalClasses="color-static"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [searchResults, emptySearchConfig, onRestartSearch]);

  return (
    <div className="search-list standard">
      <div className="search-list-info bg_color_second">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Preline preline={searchConfiguration.resultCount || subline} />

              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          </div>

          {(headerLink || secondaryLink) && (
            <div className="row justify-content-center mt-small" data-aos={animation.links.default}>
              <div className="col-md-10">
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="search-list-results" data-aos={animation.list.default} data-aos-delay="500">
        <div className="container">
          <div
            className={`row 
                      row-cols-1 
                      g-4`.replace(/\n\s+/g, "")}
          >
            {renderSearchListResults()}
          </div>
        </div>
      </div>
    </div>
  );
};

StandardSearchList.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardSearchList;
