import React, { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardContactInfoItem = ({ index, infoItem, contactInfoLength }) => {
  const [isActive, setIsActive] = useState(false);

  const itemRef = useRef(null);

  const onClickHandler = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const onMouseLeaveHandler = useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <div
      ref={itemRef}
      className={`contact-info-item ${isActive ? "active" : ""}`.trim()}
      style={{
        top: `${60 * index}px`,
        zIndex: contactInfoLength - index,
      }}
    >
      <button onClick={onClickHandler} className="contact-info-toggler" type="button">
        <div className="contact-info-icon">
          <Image image={infoItem.icon[0]} />
        </div>
      </button>

      <div className="contact-info-content" onMouseLeave={onMouseLeaveHandler}>
        {infoItem.image && (
          <div className="contact-info-image">
            <Image image={infoItem.image[0]} />
          </div>
        )}

        <div className="contact-info-text">
          <Preline preline={infoItem.subheader} additionalClasses="color-static" />

          <Headline
            headerType={infoItem.headerType}
            headline={infoItem.headline}
            additionalClasses="color-static"
          />

          <Text textType="html" text={infoItem.teaser} additionalClasses="color-static" />

          {(infoItem.primaryLink || infoItem.secondaryLink) && (
            <div className="mt-small">
              <div className="links">
                <LinkSwitcher
                  linkType={infoItem.primaryLinkType}
                  link={infoItem.primaryLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={infoItem.secondaryLinkType} link={infoItem.secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardContactInfoItem.propTypes = {
  index: PropTypes.number,
  infoItem: PropTypes.instanceOf(Object),
  contactInfoLength: PropTypes.number,
};

export default StandardContactInfoItem;
