import React from "react";
import PropTypes from "prop-types";

const Text = ({ textType, text, additionalClasses }) => {
  const renderText = () => {
    if (textType === "text") {
      return (
        <div className={`teaser ${additionalClasses}`.trim()}>
          <p>{text}</p>
        </div>
      );
    }

    return (
      <div
        className={`teaser body-text ${additionalClasses}`.trim()}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };

  return text ? renderText() : <></>;
};

Text.propTypes = {
  textType: PropTypes.string,
  text: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Text.defaultProps = {
  additionalClasses: "",
};

export default Text;
