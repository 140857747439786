import React from "react";
import PropTypes from "prop-types";

const GroupedTable = ({ properties: { table, additionalClasses } }) => {
  return (
    <div className={`table-responsive ${additionalClasses}`.trim()}>
      <table className="table table-hover">
        <tbody>
          {table.map((tableRows, index) => (
            <React.Fragment key={`key_${index + tableRows.length}`}>
              {tableRows[0] !== "" && (
                <tr key={`key_${tableRows[0] || Math.random()}`} className="table-group-heading">
                  <th key={`key_${tableRows[0] || Math.random()}`} scope="row">
                    {tableRows[0]}
                  </th>
                </tr>
              )}

              <tr key={`key_${index || Math.random()}`}>
                {tableRows.slice(1).map((tableCell, cellIndex) => {
                  return <td key={`key_${cellIndex || Math.random()}`}>{tableCell}</td>;
                })}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

GroupedTable.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default GroupedTable;
