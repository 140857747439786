import React, { useState, useEffect, useCallback, useContext, useRef } from "react";

import TranslationContext from "../Context/Translation";
import FooterSwitcher from "../Components/Footer/FooterSwitcher";

import { REACT_APP_FOOTER } from "../Constants/APIConstants";

import { getApi } from "../Utils/ApiUtils";

const FooterContainer = () => {
  const { translation } = useContext(TranslationContext);
  const currentTranslation = useRef(null);

  const [isFooterLoaded, setFooterLoaded] = useState(false);
  const [footer, setFooter] = useState(false);

  const loadFooter = useCallback(async () => {
    const activeTranslation = translation.find((lang) => lang.active === 1);

    const url =
      activeTranslation.link.split("/")[1].length === 2
        ? `/${activeTranslation.link.split("/")[1]}${REACT_APP_FOOTER}`
        : REACT_APP_FOOTER;

    try {
      const { data } = await getApi(url);

      if (data.content?.colPos0?.length) {
        setFooter(data.content.colPos0);
        setFooterLoaded(true);
      }
    } catch (error) {
      setFooterLoaded(false);
    }
  }, [translation]);

  useEffect(() => {
    // without this check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context
    // also we ensure this way that activeTranslation below will not be undefined
    if (translation.length) {
      const activeTranslation = translation.find((lang) => lang.active === 1);

      // we need to remember the current translation language after we initially set
      // translations in Context
      if (!currentTranslation.current) {
        currentTranslation.current = activeTranslation;
      }

      // AND after current translation language is changed
      if (currentTranslation.current.twoLetterIsoCode !== activeTranslation.twoLetterIsoCode) {
        currentTranslation.current = activeTranslation;

        setFooterLoaded(false);
      }
    }
  }, [translation]);

  useEffect(() => {
    // without second check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context and
    // isFooterLoaded will be reset
    // also we ensure that activeTranslation in loadFooter() will not be undefined
    if (!isFooterLoaded && translation.length) {
      loadFooter();
    }
  }, [translation, isFooterLoaded, loadFooter]);

  const renderFooter = () => {
    if (!isFooterLoaded) {
      return <></>;
    }

    if (isFooterLoaded && footer) {
      return <FooterSwitcher footer={footer} />;
    }

    return <></>;
  };

  return renderFooter();
};

export default FooterContainer;
