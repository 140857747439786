import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import Masonry from "react-masonry-component";

import TranslationContext from "../../../Context/Translation";

import MasonryNewsListItem from "./MasonryNewsListItem";

const MasonryNewsList = ({ properties: { news, columnsPerRow, hideDate, animation } }) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderNews = useCallback(() => {
    return news.map((newsItem) => {
      return (
        <MasonryNewsListItem
          news={newsItem}
          key={`key_${newsItem.title || Math.random()}`}
          linkType={newsItem.linkType}
          linkTitle={
            newsItem.linkTitle ||
            (activeTranslation.twoLetterIsoCode === "de" ? "Weiterlesen" : "Read More")
          }
          hideDate={hideDate}
          columnsPerRow={columnsPerRow}
          animation={animation}
        />
      );
    });
  }, [news, activeTranslation, hideDate, columnsPerRow, animation]);

  return (
    <div className="container news-list masonry">
      {news.length > 0 && <Masonry className="masonry-grid row g-4">{renderNews()}</Masonry>}
    </div>
  );
};

MasonryNewsList.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MasonryNewsList;
