import React from "react";
import PropTypes from "prop-types";

const StandardTable = ({ properties: { table, additionalClasses } }) => {
  return (
    <div className={`table-responsive ${additionalClasses}`.trim()}>
      <table className="table table-hover">
        <thead>
          <tr>
            {table[0].map((tableHeading) => (
              <th key={`key_${tableHeading || Math.random()}`} scope="col">
                {tableHeading}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {table.slice(1).map((tableRows, index) => (
            <tr key={`key_${index || Math.random()}`}>
              {tableRows.map((tableCell) => (
                <td key={`key_${Math.random()}`}>{tableCell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

StandardTable.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardTable;
