/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { Modal } from "bootstrap";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

import TranslationContext from "../../../Context/Translation";

const { REACT_APP_API_BASE_URL } = process.env;

const StandardLoginModal = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    formLink,
    descriptionLink,
    descriptionBackground,
    descriptionImage,
    descriptionHeader,
    descriptionText,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const modal = useRef(null);

  useEffect(() => {
    modal.current = new Modal(document.getElementById("loginModal"));

    document.addEventListener("click", (e) => {
      if (e.target.target === "_login") {
        e.preventDefault();

        setTimeout(() => {
          modal.current.show();
        }, 100);
      }
    });
  }, []);

  return (
    <div id="loginModal" className="modal login-modal standard fade" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />

            <div className="modal-title">
              <Headline
                headline={header}
                headerType={headerType}
                headerDisplayStyle={headerDisplayStyle}
              />
            </div>
          </div>
          <div className="modal-body">
            <form
              className="login-modal-form"
              method="POST"
              action={
                formLink.type === "page" ? REACT_APP_API_BASE_URL + formLink.url : formLink.url
              }
              noValidate="novalidate"
              target="_blank"
            >
              <div className="login-modal-input">
                <div className="form-floating">
                  <input
                    id="loginUsername"
                    type="text"
                    name="username"
                    placeholder={
                      activeTranslation.twoLetterIsoCode === "de" ? "Benutzer" : "Username"
                    }
                    className="form-control color-static"
                  />

                  <label
                    htmlFor="loginUsername"
                    className="form-label color-static"
                    dangerouslySetInnerHTML={{
                      __html: activeTranslation.twoLetterIsoCode === "de" ? "Benutzer" : "Username",
                    }}
                  />
                </div>
              </div>

              <div className="login-modal-input">
                <div className="form-floating">
                  <input
                    id="loginPassword"
                    type="password"
                    name="password"
                    placeholder={
                      activeTranslation.twoLetterIsoCode === "de" ? "Passwort" : "Password"
                    }
                    className="form-control color-static"
                  />

                  <label
                    htmlFor="loginPassword"
                    className="form-label color-static"
                    dangerouslySetInnerHTML={{
                      __html: activeTranslation.twoLetterIsoCode === "de" ? "Passwort" : "Password",
                    }}
                  />
                </div>
              </div>

              <div className="login-modal-submit">
                <button type="submit" className="btn btn-primary ">
                  Login
                </button>
              </div>
            </form>

            <a
              href={`${
                descriptionLink.type === "page"
                  ? REACT_APP_API_BASE_URL + descriptionLink.url
                  : descriptionLink.url
              }`}
              target="_blank"
              className="login-modal-description"
              style={{ backgroundImage: `url(${descriptionBackground.publicUrl})` }}
              rel="noreferrer"
            >
              <div className="login-modal-description-content">
                <div className="login-modal-description-text">
                  <Text textType="html" text={descriptionHeader} />
                </div>

                <div className="login-modal-description-text">
                  <Text textType="html" text={descriptionText} />
                </div>
              </div>

              <Image image={descriptionImage} additionalClasses="login-modal-description-image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

StandardLoginModal.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardLoginModal;
