import React from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const SecondaryCTA = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    animation,
  },
}) => {
  return (
    <div className="container cta secondary">
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {(headerLink || secondaryLink) && (
        <div className="row justify-content-center mt-small" data-aos={animation.links.default}>
          <div className="col-md-10">
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                // additionalClasses="btn-lg rounded-pill"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SecondaryCTA.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryCTA;
