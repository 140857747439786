import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardAccordionGalleryItem = ({ accordionCard, id, index, animation }) => {
  const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = accordionCard;

  return (
    <div className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg}`}>
      <div className="card w-100">
        <div className="card-body">
          {accordionCard.image[0] && (
            <div className="mb-small">
              <div className="card-image">
                <Image image={accordionCard.image[0]} />
              </div>
            </div>
          )}

          <div className="accordion-flush" id={`accordion-${id}-${index}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse-${index}`}
              aria-expanded="false"
              aria-controls={`flush-collapse-${index}`}
            >
              <Headline
                headerType="h3"
                headline={accordionCard.header}
                additionalClasses="color-static"
              />
            </button>

            <div
              id={`flush-collapse-${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`flush-heading${index}`}
              data-bs-parent={`#${id}`}
            >
              <div className="accordion-body">
                {accordionCard.textblock && (
                  <div className="mt-xsmall">
                    <Text
                      textType="html"
                      text={accordionCard.textblock}
                      additionalClasses="color-static"
                    />
                  </div>
                )}

                {accordionCard.highlight && (
                  <div className="mt-xsmall">
                    <div className="accordion-highlight">
                      <Text
                        textType="text"
                        text={accordionCard.highlight}
                        additionalClasses="color-static"
                      />
                    </div>
                  </div>
                )}

                {(accordionCard.primaryLink || accordionCard.secondaryLink) && (
                  <div className="mt-small" data-aos={animation.links.default}>
                    <div className="links">
                      <LinkSwitcher
                        linkType={accordionCard.primaryLinkType}
                        link={accordionCard.primaryLink}
                        // additionalClasses="btn-lg rounded-pill color-static"
                        additionalClasses="color-static"
                      />

                      <LinkSwitcher
                        linkType={accordionCard.secondaryLinkType}
                        link={accordionCard.secondaryLink}
                        additionalClasses="color-static"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StandardAccordionGalleryItem.propTypes = {
  accordionCard: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  index: PropTypes.number,
  animation: PropTypes.instanceOf(Object),
};

export default StandardAccordionGalleryItem;
