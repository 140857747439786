import React from "react";
import PropTypes from "prop-types";

import ElementSwitcher from "../ContentElements/ElementSwitcher";

/**
 * one column layout full width
 *
 * @returns {JSX.Element}
 * @constructor
 */
const StandardLayout = ({ content, pageInfo }) => {
  const renderElements = () => {
    if (content.colPos0) {
      return (
        <ElementSwitcher
          contentElements={content.colPos0}
          breadcrumbs={pageInfo.breadcrumbs}
          sidebar={content.colPos2}
        />
      );
    }

    return <></>;
  };

  return renderElements();
};

StandardLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
  pageInfo: PropTypes.instanceOf(Object),
};

export default StandardLayout;
