import React from "react";
import PropTypes from "prop-types";

const ComponentMissing = ({ type, subType }) => {
  return (
    <div className="container debug component-missing">
      <div className="alert">
        <span className="text-light">{type}</span> matching with type{" "}
        <span className="text-light">{subType}</span> is missing.
      </div>
    </div>
  );
};

ComponentMissing.propTypes = {
  type: PropTypes.string,
  subType: PropTypes.string,
};

export default ComponentMissing;
