import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Image from "../../Atoms/Image";
import Text from "../../Atoms/Text";

const IconWallTAILItem = ({ headline, teaser, image }) => {
  return (
    <div className="col text-center">
      {image && (
        <div className="mb-xsmall">
          <Image image={image} />
        </div>
      )}

      <Headline headerType="h4" headline={headline} />

      <Text textType="html" text={teaser} />
    </div>
  );
};

IconWallTAILItem.propTypes = {
  headline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  length: PropTypes.number,
};

export default IconWallTAILItem;
