import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardQuoteAndReference = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    companyName,
    companyLogo,
    customerName,
    customerPosition,
    customerImage,
    quote,
    reference,
    mirrored,
    animation,
  },
}) => {
  const getQuoteContainer = useCallback(() => {
    return (
      <div className="col-md-8 order-1 order-md-0">
        <div className="card-body quote-and-reference-quote">
          {companyLogo && (
            <div className="mb-medium">
              <div className="quote-and-reference-logo">
                <div className="card-image">
                  <Image image={companyLogo} animation={animation.image.default} />
                </div>
              </div>
            </div>
          )}

          <div className="quote-and-reference-company">
            <Headline headerType="h3" headline={companyName} />
          </div>

          <figure className="quote-and-reference-content">
            <blockquote className="quote-and-reference-text">
              {quote && (
                <div className="mt-xsmall">
                  <Text textType="html" text={quote} />
                </div>
              )}
            </blockquote>

            <figcaption>
              {customerImage && (
                <div className="mt-medium">
                  <div className="quote-and-reference-photo">
                    <div className="card-image">
                      <Image image={customerImage} animation={animation.image.default} />
                    </div>
                  </div>
                </div>
              )}

              {(customerName || customerPosition) && (
                <div className="mt-small">
                  <div className="quote-and-reference-meta">
                    <Headline headerType="h4" headline={customerName} />

                    <Preline preline={customerPosition} />
                  </div>
                </div>
              )}
            </figcaption>
          </figure>
        </div>
      </div>
    );
  }, [companyLogo, companyName, quote, customerImage, customerName, customerPosition, animation]);

  const getReferenceContainer = useCallback(() => {
    return (
      <div className="col-md-4 order-2 order-md-0">
        <div className="card-body quote-and-reference-reference">
          <Text textType="html" text={reference} />
        </div>
      </div>
    );
  }, [reference]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="card">
          <div className="row flex-row-reverse mirrored">
            {getQuoteContainer()}
            {getReferenceContainer()}
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="row">
          {getQuoteContainer()}
          {getReferenceContainer()}
        </div>
      </div>
    );
  }, [mirrored, getQuoteContainer, getReferenceContainer]);

  return (
    <div className="container quote-and-reference standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-small">{getContent()}</div>
    </div>
  );
};

StandardQuoteAndReference.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardQuoteAndReference;
