import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import qs from "qs";

import Pagination from "react-js-pagination";

import TranslationContext from "../../Context/Translation";
import ElementSwitcher from "../ContentElements/ElementSwitcher";

import { postApi } from "../../Utils/ApiUtils";

import { REACT_APP_SEARCH } from "../../Constants/APIConstants";

import { ReactComponent as PaginationFirst } from "../../Images/svg/pagination-first.svg";
import { ReactComponent as PaginationLast } from "../../Images/svg/pagination-last.svg";
import { ReactComponent as PaginationNext } from "../../Images/svg/pagination-next.svg";
import { ReactComponent as PaginationPrevious } from "../../Images/svg/pagination-previous.svg";

const SearchListLayout = ({ content, pageInfo }) => {
  const { translation } = useContext(TranslationContext);

  const location = useLocation();

  const [searchResults, setSearchResults] = useState(content);
  const [page, setPage] = useState(1);

  const settings = useRef({
    pagination: content.colPos0.find((el) => el.type === "ahoiitoolkit_searchlist").content.data
      .configuration,
    searchValue: location.state?.searchValue || location.search.split("=")[1],
    searchListUrl: location.state?.searchListUrl || REACT_APP_SEARCH,
  });

  const onPageSelect = useCallback((currentPage) => {
    setPage(currentPage);
  }, []);

  const getUrl = useCallback(() => {
    const activeTranslation = translation.find((lang) => lang.active === 1).twoLetterIsoCode;

    const languageUrl = activeTranslation !== "de" ? activeTranslation : "";
    const baseUrl = settings.current.searchListUrl;
    const pageUrl = page !== 1 ? `/page-${page}` : "";

    return `${languageUrl}${baseUrl}${pageUrl}`;
  }, [translation, page]);

  const loadSearchResults = useCallback(async () => {
    try {
      const { data } = await postApi(
        getUrl(),
        qs.stringify({
          search: settings.current.searchValue,
          page,
        }),
      );

      if (data.content?.colPos0?.length) {
        // have to change pagination data before re-render
        settings.current.pagination = {
          ...data.content.colPos0[0].content.data.pagination,
          itemsPerPage: data.content.colPos0[0].content.data.settings.PostsPerPage,
          resultCount: data.content.colPos0[0].content.data.itemTotalCount,
        };

        setSearchResults(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page, getUrl]);

  useEffect(() => {
    loadSearchResults();
  }, [location.state, location.search, loadSearchResults]);

  return (
    <div className="search-list">
      <ElementSwitcher contentElements={searchResults.colPos0} breadcrumbs={pageInfo.breadcrumbs} />

      <div className="mt-small">
        {settings.current.pagination.maxPages > 1 && (
          <div className="container pb-small">
            <div className="row">
              <div className="col-12">
                <nav aria-label="News pagination">
                  <Pagination
                    innerClass="pagination justify-content-center"
                    itemClass="page-item"
                    itemClassFirst="page-item"
                    itemClassPrev="page-item"
                    itemClassNext="page-item"
                    itemClassLast="page-item"
                    linkClass="page-link"
                    linkClassFirst="page-link page-link-first"
                    linkClassPrev="page-link page-link-prev"
                    linkClassNext="page-link page-link-next"
                    linkClassLast="page-link page-link-last"
                    activePage={page}
                    itemsCountPerPage={settings.current.pagination.itemsPerPage}
                    totalItemsCount={settings.current.pagination.resultCount}
                    pageRangeDisplayed={5}
                    onChange={onPageSelect}
                    hideFirstLastPages={false}
                    // prevPageText={<> &lsaquo;</>}
                    // nextPageText={<>&rsaquo;</>}
                    // firstPageText="«"
                    // lastPageText="»"
                    prevPageText={<PaginationPrevious />}
                    nextPageText={<PaginationNext />}
                    firstPageText={<PaginationFirst />}
                    lastPageText={<PaginationLast />}
                  />
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SearchListLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
  pageInfo: PropTypes.instanceOf(Object),
};

export default SearchListLayout;
