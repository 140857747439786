import React from "react";
import PropTypes from "prop-types";

import StandardBreadcrumbs from "./StandardBreadcrumbs";
import ComponentMissing from "../../Debug/ComponentMissing";

const BreadcrumbsSwitcher = ({ layout, breadcrumbs }) => {
  const properties = {
    breadcrumbs,
  };

  function switchBreadcrumbs() {
    switch (layout) {
      case "standard":
        return <StandardBreadcrumbs properties={properties} />;
      default:
        return <ComponentMissing type="Breadcrumbs" subType={layout} />;
    }
  }

  return switchBreadcrumbs();
};

BreadcrumbsSwitcher.propTypes = {
  layout: PropTypes.string,
  breadcrumbs: PropTypes.instanceOf(Array),
};

export default BreadcrumbsSwitcher;
