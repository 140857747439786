import axios from "axios";
import { REACT_APP_API_BASE_URL } from "../Constants/APIConstants";

const apiClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 10000,
  headers: { "Cache-Control": "no-cache" },
});

export default apiClient;
