import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";

import TranslationContext from "../../../Context/Translation";

import { createInvestmentChart } from "../../../Utils/ChartUtils";

const StandardInvestmentCalculator = ({
  properties: { header, headerType, headerDisplayStyle, calculatorOptions },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const [investmentData, setInvestmentData] = useState({
    singlePayment: 5000,
    monthlyPayment: 250,
    years: 10,
    percentage: calculatorOptions[1].return,
  });

  const chart = useRef(null);

  // one line that shows, how much money u invest over the time
  // one line that shows, how much money u invest over the time
  // on scale we have years but for more correct data, i made a monthly calculation
  // if it is a problem u can change the number of calculations in the array
  // or if u need, u can add date as parameter
  const calculatePayment = useCallback(() => {
    const payed = [];
    const currentDate = new Date();

    for (let i = 0; i <= investmentData.years * 12; i += 1) {
      payed.push({
        date: new Date(currentDate.getFullYear(), currentDate.getMonth() + i),
        payed: +investmentData.singlePayment + i * +investmentData.monthlyPayment,
      });
    }

    return payed;
  }, [investmentData]);

  // the monthly calculated outcome
  const calculateResult = useCallback(() => {
    const result = [];
    const currentDate = new Date();

    const q = 1.0 + parseFloat(investmentData.percentage) / 100 / 12;
    const K = +investmentData.singlePayment;
    const KN = +investmentData.monthlyPayment;

    for (let t = 0; t <= investmentData.years * 12; t += 1) {
      const base = K * q ** t;

      let secondPart = 0;

      for (let n = 0; n < t; n += 1) {
        secondPart += KN * q ** n;
      }

      result.push({
        date: new Date(currentDate.getFullYear(), currentDate.getMonth() + t),
        capital: base + secondPart,
      });
    }

    return result;
  }, [investmentData]);

  useEffect(() => {
    const payment = calculatePayment();
    const result = calculateResult();
    const chartData = payment
      .map((el, index) => {
        return { ...el, ...result[index] };
      })
      .filter((el, index) => !(index % 12) && el); // COMMENT FILTER TO SEE THE DIFFERENCE

    if (!chart.current) {
      chart.current = createInvestmentChart(
        `chartdiv_investment_chart_${calculatorOptions.length}`,
        chartData,
        activeTranslation.twoLetterIsoCode,
      );
    } else {
      chart.current.data = chartData;
    }

    // INSTEAD OF RECREATING CHART ON EACH UPDATE JUST UPDATE ITS DATA
    // () => chart.current.dispose();
  });

  const handleInvestmentDataChange = useCallback(
    (e) => {
      const {
        name,
        value,
        attributes: { min, max },
      } = e.target;

      if (!value) {
        setInvestmentData({ ...investmentData, [name]: 0 });

        return;
      }

      if (min?.value && value < +min.value) {
        setInvestmentData({ ...investmentData, [name]: +min.value });

        return;
      }

      if (max?.value && value > +max.value) {
        setInvestmentData({ ...investmentData, [name]: +max.value });

        return;
      }

      setInvestmentData({ ...investmentData, [name]: value });
    },
    [investmentData],
  );

  const renderInvestmentControls = useCallback(() => {
    return (
      <>
        <div className="investment-calculator-type">
          <div className="investment-calculator-name">
            <span>1.</span>{" "}
            {activeTranslation.twoLetterIsoCode === "de" ? "Anlagebetrag" : "Investment Amount"}
          </div>

          <div className="investment-calculator-fields mt-xxsmall">
            <label
              htmlFor="ainmalzahlung"
              className="investment-calculator-label form-label form-text"
            >
              {activeTranslation.twoLetterIsoCode === "de" ? "Einmalzahlung" : "One-time Payment"}
            </label>

            <div className="investment-calculator-field">
              <input
                type="range"
                name="singlePayment"
                min="0"
                max="99999"
                value={investmentData.singlePayment}
                className="form-range investment-calculator-range"
                onChange={handleInvestmentDataChange}
              />

              <input
                id="einmalzahlung"
                type="number"
                min="0"
                max="99999"
                name="singlePayment"
                value={investmentData.singlePayment}
                className="form-control investment-calculator-input color-static"
                onChange={handleInvestmentDataChange}
              />

              <span>€</span>
            </div>
          </div>

          <div className="investment-calculator-fields mt-xxsmall">
            <label
              htmlFor="monatlicher-sparbetrag"
              className="investment-calculator-label form-label form-text"
            >
              {activeTranslation.twoLetterIsoCode === "de"
                ? "Monatlicher Sparbetrag"
                : "Monthly Savings Amount"}
            </label>

            <div className="investment-calculator-field">
              <input
                type="range"
                name="monthlyPayment"
                min="0"
                max="5000"
                value={investmentData.monthlyPayment}
                className="form-range investment-calculator-range"
                onChange={handleInvestmentDataChange}
              />

              <input
                id="monatlicher-sparbetrag"
                type="number"
                min="0"
                max="5000"
                name="monthlyPayment"
                value={investmentData.monthlyPayment}
                className="form-control investment-calculator-input color-static"
                onChange={handleInvestmentDataChange}
              />

              <span>€</span>
            </div>
          </div>
        </div>

        <div className="investment-calculator-type mt-xxsmall">
          <div className="investment-calculator-name">
            <span>2.</span>{" "}
            {activeTranslation.twoLetterIsoCode === "de" ? "Anlagezeitraum" : "Investment Period"}
          </div>

          <div className="investment-calculator-fields mt-xxsmall">
            <div className="investment-calculator-field">
              <input
                id="anlagezeitraum"
                type="range"
                min="0"
                max="100"
                name="years"
                value={investmentData.years}
                className="form-range investment-calculator-range"
                onChange={handleInvestmentDataChange}
              />

              <input
                id="anlagezeitraum"
                type="number"
                min="0"
                max="100"
                name="years"
                value={investmentData.years}
                className="form-control investment-calculator-input color-static"
                onChange={handleInvestmentDataChange}
              />

              <span>{activeTranslation.twoLetterIsoCode === "de" ? "Jahre" : "Years"}</span>
            </div>
          </div>
        </div>

        <div className="investment-calculator-type mt-xxsmall">
          <div className="investment-calculator-name">
            <span>3.</span>{" "}
            {activeTranslation.twoLetterIsoCode === "de" ? "ETF-Portfolio" : "ETF-Portfolio"}
          </div>

          <div className="investment-calculator-fields mt-xxsmall">
            <div className="investment-calculator-field flex-column align-items-start">
              {calculatorOptions.map((option) => (
                <div key={option.id} className="form-check">
                  <input
                    id={option.id}
                    type="radio"
                    name="percentage"
                    value={option.return}
                    defaultChecked={investmentData.percentage === option.return}
                    className="form-check-input"
                    onChange={handleInvestmentDataChange}
                  />
                  <label htmlFor={option.id} className="form-check-label">
                    {option.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }, [activeTranslation, investmentData, handleInvestmentDataChange, calculatorOptions]);

  return (
    <div className="container investment-calculator standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {calculatorOptions.length > 0 && (
        <div className="row mt-small">
          <div className="col-md-5">{renderInvestmentControls()}</div>
          <div className="col-md-7 mt-4 mt-md-0">
            <div className="chart">
              <div
                id={`chartdiv_investment_chart_${calculatorOptions.length}`}
                className="performance_chart"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardInvestmentCalculator.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardInvestmentCalculator;
