import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardAccordionGalleryItem from "./StandardAccordionGalleryItem";

const StandardAccordionGallery = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    id,
    accordionCards,
    animation,
  },
}) => {
  const renderAccordionCards = useCallback(() => {
    return accordionCards.map((accordionCard, index) => {
      return (
        <StandardAccordionGalleryItem
          accordionCard={accordionCard}
          key={`key_${accordionCard.header || Math.random()}`}
          id={`accordion-${id}-${index}`}
          index={index}
          animation={animation}
        />
      );
    });
  }, [accordionCards, id, animation]);

  return (
    <div className="container accordion-gallery standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {accordionCards.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center g-4">{renderAccordionCards()}</div>
        </div>
      )}
    </div>
  );
};

StandardAccordionGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardAccordionGallery;
