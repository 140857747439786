import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { ReactComponent as Positive } from "../../../Images/svg/comparison-positive.svg";
import { ReactComponent as Negative } from "../../../Images/svg/comparison-negative.svg";
import { ReactComponent as Neutral } from "../../../Images/svg/comparison-neutral.svg";

const StandardComparisonGalleryItem = ({ comparisonCard, animation }) => {
  const getBulletTypeIcon = useCallback((bulletType) => {
    switch (bulletType) {
      case "positive":
        return <Positive />;
      case "negative":
        return <Negative />;
      case "neutral":
        return <Neutral />;
      default:
        return <Neutral />;
    }
  }, []);

  const renderBullets = useCallback(() => {
    return comparisonCard.bullets.map((bullet) => {
      return (
        <div key={`key_${bullet.bullet_name || Math.random()}`} className="bullets-item">
          <div className="pt-xsmall pb-xsmall bullets-icon">
            <span>{getBulletTypeIcon(bullet.bullet_type)}</span>
          </div>
          <div className="pt-xsmall pb-xsmall bullets-content">{bullet.bullet_name}</div>
        </div>
      );
    });
  }, [comparisonCard.bullets, getBulletTypeIcon]);

  const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = comparisonCard;

  return (
    <div className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg} d-flex`}>
      <div className="card w-100">
        <div className={`card-image ${comparisonCard.bgColorClass}`.trim()}>
          <Headline
            headerType="h3"
            headline={comparisonCard.header}
            additionalClasses="color-static"
          />
        </div>

        <div className="card-body">
          {comparisonCard.bullets.length > 0 && (
            <div className="mt-xsmall">
              <div className="bullets">{renderBullets()}</div>
            </div>
          )}

          {comparisonCard.primary_link && (
            <div className="mt-medium" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={comparisonCard.primaryLinkType}
                  link={comparisonCard.primary_link}
                  // additionalClasses="btn-lg rounded-pill"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardComparisonGalleryItem.propTypes = {
  comparisonCard: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default StandardComparisonGalleryItem;
