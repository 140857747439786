import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const LargeTextAndContact = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    companyName,
    contactName,
    contactPosition,
    mirrored,
    animation,
  },
}) => {
  const getImageContainer = useCallback(() => {
    return (
      <div className="col-md-9 order-1 order-md-0 mb-4 mb-md-0 d-flex align-items-center">
        <Image image={image} animation={animation.image.default} />
      </div>
    );
  }, [image, animation]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-md-3 order-2 order-md-0">
        <div className="text-and-contact-content">
          {(subline || header || teaser || headerLink || secondaryLink) && (
            <div className="mb-small">
              <div className="text-and-contact-info">
                <Preline preline={subline} />

                <Headline
                  headerType={headerType}
                  headerStyle={headerDisplayStyle}
                  headline={header}
                />

                {teaser && (
                  <div className="mt-xsmall">
                    <Text textType="html" text={teaser} />
                  </div>
                )}

                {(headerLink || secondaryLink) && (
                  <div className="mt-small" data-aos={animation.links.default}>
                    <div className="links">
                      <LinkSwitcher
                        linkType={headerLinkType}
                        link={headerLink}
                        // additionalClasses="btn-lg rounded-pill"
                      />

                      <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="text-and-contact-contact">
            <Headline
              headerType="h5"
              headline={companyName}
              additionalClasses="text-and-contact-company"
            />

            <Headline
              headerType="h5"
              headline={contactPosition}
              additionalClasses="text-and-contact-position"
            />

            {teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={contactName} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    contactName,
    companyName,
    contactPosition,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse mirrored">
          {getImageContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className="row">
        {getImageContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer]);

  return (
    <div className="text-and-contact large">
      <div className="container">{getContent()}</div>
    </div>
  );
};

LargeTextAndContact.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default LargeTextAndContact;
