import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";

// eslint-disable-next-line import/no-cycle
import ElementSwitcher from "../ElementSwitcher";

const StandardAccordionAndCategories = ({
  properties: { header, headerType, headerDisplayStyle, subline, items, tabContent },
}) => {
  useEffect(() => {
    const accordions = document.querySelectorAll(".accordion-and-categories .accordion");

    accordions.forEach((accordion) => {
      accordion.classList.add("color-static");

      accordion.querySelector(".headline")?.classList.add("color-static");
    });
  }, []);

  const renderTabButton = useCallback((label, id, index) => {
    return (
      <a
        key={id}
        className={`nav-link ${index ? "" : "active"}`}
        id={`nav-${id}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#nav-${id}`}
        role="tab"
        aria-controls={`nav-${id}`}
        aria-selected={index ? "false" : "true"}
      >
        {label}
      </a>
    );
  }, []);

  const renderTabContent = useCallback(
    (id, index) => {
      return (
        <div
          key={id}
          className={`tab-pane fade show ${index ? "" : "active"} `}
          id={`nav-${id}`}
          role="tabpanel"
          aria-labelledby={`nav-${id}-tab`}
        >
          {tabContent.map((item) => {
            return (
              <React.Fragment key={item.id}>
                {item.tabId === id ? (
                  <ElementSwitcher contentElements={[item]} breadcrumbs={[]} isTabCycle />
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    },
    [tabContent],
  );

  const renderHeader = useCallback(() => {
    return (
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {items.map((item, index) => renderTabButton(item.label, item.tabId, index))}
        </div>
      </nav>
    );
  }, [items, renderTabButton]);

  const renderContent = useCallback(() => {
    return (
      <div className="tab-content" id="nav-tabContent">
        {items.map((item, index) => renderTabContent(item.tabId, index))}
      </div>
    );
  }, [items, renderTabContent]);

  return (
    <div className="container accordion-and-categories standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {items.length > 0 && (
            <div className="mt-small">
              {renderHeader()}
              {renderContent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardAccordionAndCategories.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardAccordionAndCategories;
