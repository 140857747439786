import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

const StandardContactGalleryItem = ({ contactCard, animation }) => {
  const { image, contactName, contactDescription, linkedinLink, xingLink } = contactCard;

  return (
    <div className="col d-flex contact-gallery-item">
      <div className="card w-100">
        {image[0] && (
          <div className="mb-small">
            <div className="card-image">
              <Image image={image[0]} animation={animation.image.default} />
            </div>
          </div>
        )}

        <Headline headerType="h5" headline={contactName} />

        {contactDescription && (
          <div className="mt-xxsmall">
            <Text textType="text" text={contactDescription} />
          </div>
        )}

        {(linkedinLink || xingLink) && (
          <div className="mt-xsmall" data-aos={animation.links.default}>
            <div className="links">
              {xingLink && (
                <a
                  key={`key_${xingLink.url || Math.random()}`}
                  href={xingLink.url}
                  noopener="true"
                  noreferrer="true"
                  className="contact-gallery-item-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-xing" />
                </a>
              )}

              {linkedinLink && (
                <a
                  key={`key_${linkedinLink.url || Math.random()}`}
                  href={linkedinLink.url}
                  noopener="true"
                  noreferrer="true"
                  className="contact-gallery-item-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StandardContactGalleryItem.propTypes = {
  contactCard: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default StandardContactGalleryItem;
