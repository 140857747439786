import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardContactGalleryItem from "./StandardContactGalleryItem";

const StandardContactGallery = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    contactCards,
    columnsPerRow,
    animation,
  },
}) => {
  const renderContactCards = useCallback(() => {
    return contactCards.map((contactCard) => {
      return (
        <StandardContactGalleryItem
          contactCard={contactCard}
          key={`key_${contactCard.header || Math.random()}`}
          animation={animation}
        />
      );
    });
  }, [contactCards, animation]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container contact-gallery standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {contactCards.length > 0 && (
        <div className="mt-small">
          <div
            className={`row 
                        justify-content-center 
                        row-cols-${colXs} 
                        row-cols-sm-${colSm} 
                        row-cols-md-${colMd} 
                        row-cols-lg-${colLg} 
                        g-5`.replace(/\n\s+/g, "")}
          >
            {renderContactCards()}
          </div>
        </div>
      )}
    </div>
  );
};

StandardContactGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardContactGallery;
