/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  id,
  label,
  formData,
  validationErrors,
  onChangeHandler,
  description,
  additionalClasses,
}) => {
  const onChange = (event) => {
    onChangeHandler({
      target: { name: event.target.name, value: !formData[id] },
    });
  };

  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      <div className="form-check">
        <input
          type="checkbox"
          id={id}
          name={id}
          checked={formData[id]}
          onChange={onChange}
          className={`form-check-input ${validationErrors ? "is-invalid" : ""}`.trim()}
        />

        {(label || description) && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            htmlFor={id}
            className={`form-check-label ${validationErrors ? "is-invalid" : ""}`.trim()}
            dangerouslySetInnerHTML={{ __html: label || description }}
          />
        )}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.string,
  onChangeHandler: PropTypes.func,
  description: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Checkbox.defaultProps = {
  additionalClasses: "",
};

export default Checkbox;
