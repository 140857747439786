import React from "react";
import PropTypes from "prop-types";

import StandardMenuSubPages from "./StandardMenuSubPages";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuSubPagesSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const properties = {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  };

  function switchMenuSubPages() {
    switch (layout) {
      case "standard":
        return <StandardMenuSubPages properties={properties} />;
      default:
        return <ComponentMissing type="MenuSubPages" subType={layout} />;
    }
  }

  return switchMenuSubPages();
};

MenuSubPagesSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuSubPagesSwitcher;
