import React from "react";
import PropTypes from "prop-types";

const Submit = ({ id, label, animation }) => {
  return (
    <div className="col-12">
      <input
        id={id}
        type="submit"
        value={label}
        className="btn btn-primary"
        data-aos={animation.links.default}
      />
    </div>
  );
};

Submit.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default Submit;
