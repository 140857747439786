import React, { useCallback } from "react";
import PropTypes from "prop-types";

const Headline = ({ headerType, headerStyle, headline, additionalClasses }) => {
  const getClasses = useCallback(() => {
    switch (headerStyle) {
      case "display-1":
        return "display-1";
      case "display-2":
        return "display-2";
      case "display-3":
        return "display-3";
      case "display-4":
        return "display-4";
      case "display-5":
        return "display-5";
      case "display-6":
        return "display-6";
      default:
        return "";
    }
  }, [headerStyle]);

  const renderHeadline = () => {
    switch (headerType) {
      case "h1":
        return (
          <h1
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      case "h2":
        return (
          <h2
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      case "h3":
        return (
          <h3
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      case "h4":
        return (
          <h4
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      case "h5":
        return (
          <h5
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      case "h6":
        return (
          <h6
            className={`headline ${getClasses() + additionalClasses}`.trim()}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        );
      default:
        return <span className="visually-hidden" dangerouslySetInnerHTML={{ __html: headline }} />;
    }
  };

  return headline ? renderHeadline() : <></>;
};

Headline.propTypes = {
  headerType: PropTypes.string,
  headerStyle: PropTypes.string,
  headline: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Headline.defaultProps = {
  additionalClasses: "",
};

export default Headline;
