import React from "react";
import PropTypes from "prop-types";

import StandardLoginModal from "./StandardLoginModal";
import ComponentMissing from "../../Debug/ComponentMissing";

const LoginModal = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  formLink,
  descriptionLink,
  descriptionBackground,
  descriptionImage,
  descriptionHeader,
  descriptionText,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    formLink,
    descriptionLink,
    descriptionBackground,
    descriptionImage,
    descriptionHeader,
    descriptionText,
  };

  function switchLoginModal() {
    switch (layout) {
      case "standard":
        return <StandardLoginModal properties={properties} />;
      default:
        return <ComponentMissing type="LoginModal" subType={layout} />;
    }
  }

  return switchLoginModal();
};

LoginModal.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  formLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  descriptionLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  descriptionBackground: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  descriptionImage: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  descriptionHeader: PropTypes.string,
  descriptionText: PropTypes.string,
};

export default LoginModal;
