/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { Modal } from "bootstrap";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import TranslationContext from "../../../Context/Translation";

const { REACT_APP_API_BASE_URL } = process.env;

const StandardBuyFondsModal = ({ properties: { sectionId, fonds } }) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const modal = useRef(null);
  const [isin, setIsin] = useState(fonds[0]?.isin);

  useEffect(() => {
    modal.current = new Modal(document.getElementById(sectionId));

    document.addEventListener("click", (e) => {
      if (e.target.target === "_modal" && e.target.dataset.sectionid === sectionId) {
        e.preventDefault();

        setIsin(e.target.dataset.isin);

        setTimeout(() => {
          modal.current.show();
        }, 100);
      }
    });
  }, [sectionId]);

  const renderBanks = useCallback((banks) => {
    return banks.map((banksItem) => {
      return (
        <div key={`key_${banksItem.uid}`} className="card buy-fonds-modal-list-item">
          <Image
            image={
              banksItem.image[0] && {
                publicUrl: `${REACT_APP_API_BASE_URL}/${banksItem.image[0].url}`,
                properties: { alternative: "bank_logo" },
              }
            }
            additionalClasses="buy-fonds-modal-list-item-logo"
          />

          <div className="buy-fonds-modal-list-item-links">
            <LinkSwitcher
              linkType="btn-primary"
              link={{
                publicUrl: banksItem.buyLink.url,
                title: banksItem.buyLink.label,
                target: "_blank",
              }}
            />

            <small>{banksItem.note}</small>

            <LinkSwitcher
              linkType="link-secondary"
              link={{
                publicUrl: banksItem.depotLink.url,
                title: banksItem.depotLink.label,
                target: "_blank",
              }}
            />
          </div>
        </div>
      );
    });
  }, []);

  const targetFond = fonds.find((fond) => fond.isin === isin);

  return (
    <div id={sectionId} className="modal buy-fonds-modal standard" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-close">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              {activeTranslation.twoLetterIsoCode === "de" ? "schließen" : "close"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                fill="currentColor"
                className="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg>
            </button>
          </div>

          <div className="buy-fonds-modal-inner">
            <Headline headerType="h3" headline={targetFond.headline} />

            <Text textType="html" text={targetFond.teaser} />

            {targetFond.banks.length > 0 && (
              <div className="mt-medium">
                <div className="buy-fonds-modal-list">{renderBanks(targetFond.banks)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StandardBuyFondsModal.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardBuyFondsModal;
