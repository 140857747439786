import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const HorizontalFactsAndBullets = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    facts,
    columnsPerRow,
    cols,
    alignment,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const renderBullets = useCallback((fact) => {
    return fact.bullets.map((bullet) => {
      return (
        <div key={`key_${bullet || Math.random()}`} className="bullets-item">
          {bullet}
        </div>
      );
    });
  }, []);

  const renderFacts = useCallback(() => {
    return facts.map((fact) => {
      return (
        <div key={`key_${fact.header || Math.random()}`} className="col d-flex">
          <div className="card w-100">
            {fact.image[0] && (
              <div className="card-image me-5">
                <Image image={fact.image[0]} animation={animation.image.default} />
              </div>
            )}

            <div className="facts-and-bullets-info">
              <div>
                <Headline headerType="h3" headline={fact.header} />

                {fact.teaser && (
                  <div className="mt-xxsmall">
                    <Text textType="text" text={fact.teaser} />
                  </div>
                )}
              </div>

              <div>
                {fact.bullets.length > 0 && (
                  <div className="mt-xsmall">
                    <div className={`bullets bullets-${fact.bullet_type.toLowerCase()}`}>
                      {renderBullets(fact)}
                    </div>
                  </div>
                )}

                {fact.primaryLink && (
                  <div className="mt-small" data-aos={animation.links.default}>
                    <div className="links">
                      <LinkSwitcher
                        linkType={fact.primaryLinkType}
                        link={fact.primaryLink}
                        // additionalClasses="btn-lg rounded-pill"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [facts, renderBullets, animation]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container facts-and-bullets standard">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}

          {facts.length > 0 && (
            <div className="mt-small">
              <div
                className={`row 
                          row-cols-${colXs} 
                          row-cols-sm-${colSm} 
                          row-cols-md-${colMd} 
                          row-cols-lg-${colLg} 
                          g-5`.replace(/\n\s+/g, "")}
              >
                {renderFacts()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HorizontalFactsAndBullets.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default HorizontalFactsAndBullets;
