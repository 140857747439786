import React from "react";
import PropTypes from "prop-types";

import StandardMenuSubPagesWithSearch from "./StandardMenuSubPagesWithSearch";
import SecondaryMenuSubPagesWithSearch from "./SecondaryMenuSubPagesWithSearch";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuSubPagesWithSearchSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    searchLabel,
    searchListUrl,
    searchPlaceholder,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const properties = {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    searchLabel,
    searchListUrl,
    searchPlaceholder,
    navigation,
    languages,
    activeLanguage,
  };

  function switchMenuSubpagesWithSearch() {
    switch (layout) {
      case "standard":
        return <StandardMenuSubPagesWithSearch properties={properties} />;
      case "secondary":
        return <SecondaryMenuSubPagesWithSearch properties={properties} />;
      default:
        return <ComponentMissing type="MenuSubPagesWithSearch" subType={layout} />;
    }
  }

  return switchMenuSubpagesWithSearch();
};

MenuSubPagesWithSearchSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuSubPagesWithSearchSwitcher;
