import React from "react";
import PropTypes from "prop-types";

import StandardSearchList from "./StandardSearchList";
import ComponentMissing from "../../Debug/ComponentMissing";

const SearchListSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  data,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    searchConfiguration: data.configuration,
    searchResults: data.list,
    emptySearchConfig: data.emptySearch,
    animation,
  };

  function switchSearchList() {
    switch (layout) {
      case "standard":
        return <StandardSearchList properties={properties} />;
      default:
        return <ComponentMissing type="SearchList" subType={layout} />;
    }
  }

  return switchSearchList();
};

SearchListSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default SearchListSwitcher;
