import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Countup from "react-countup";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardTextAndNumbers = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    cardHeader,
    numbers,
    mirrored,
    animation,
  },
}) => {
  const startRefs = useRef([]);

  useEffect(() => {
    const revealHandler = () => {
      if (startRefs.current.length > 0) {
        startRefs.current.forEach((startRef) => startRef());
      }
    };

    document.addEventListener("aos:in:text-and-numbers", revealHandler);

    return () => {
      document.removeEventListener("aos:in:text-and-numbers", revealHandler);
    };
  }, []);

  const getNumbersContainer = useCallback(() => {
    return (
      <div
        className="col-12 col-md-6 col-xl-4 order-1 order-md-0 mb-4 mb-md-0"
        data-aos="none"
        data-aos-id="text-and-numbers"
      >
        <div className="card">
          <div className="card-body">
            {cardHeader && (
              <div className="mb-small">
                <Headline headerType="h4" headline={cardHeader} additionalClasses="color-static" />
              </div>
            )}

            <ul className="list">
              {numbers?.map((number) => {
                return (
                  <li key={`key_${number.numberValue || Math.random()}`}>
                    <div className="text-and-numbers-value">
                      <Countup
                        start={0}
                        delay={0}
                        end={number.numberValue.replace(/[^0-9.]+/g, "")}
                        separator="."
                        decimal=","
                        prefix={number.numberValue.replace(/[0-9.]+/g, "")}
                        decimals={2}
                        duration={2}
                      >
                        {({ countUpRef, start }) => {
                          startRefs.current.push(start);
                          // eslint-disable-next-line jsx-a11y/heading-has-content
                          return <h3 className="headline color-static" ref={countUpRef} />;
                        }}
                      </Countup>
                    </div>

                    <div className="text-and-numbers-text">
                      <Text text={number.numberText} additionalClasses="color-static" />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }, [cardHeader, numbers]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-12 col-md-6 col-xl-8 order-2 order-md-0">
        <Preline preline={subline} />

        <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

        {teaser && (
          <div className="mt-xsmall">
            <Text textType="text" text={teaser} />
          </div>
        )}

        {(headerLink || secondaryLink) && (
          <div className="mt-small d-none d-lg-block" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                // additionalClasses="btn-lg rounded-pill"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        )}
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row align-items-center flex-row-reverse mirrored">
          {getNumbersContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getNumbersContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getNumbersContainer, getTextContainer]);

  return <div className="container text-and-numbers standard">{getContent()}</div>;
};

StandardTextAndNumbers.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardTextAndNumbers;
