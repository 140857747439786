import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardInvestmentQuitStep = ({
  currentStep,
  currentStepNumber,
  stepsAmount,
  stepsLabel,
  onNextStepHandler,
  onEvaluateResultHandler,
}) => {
  const [currentAnswer, setCurrentAnswer] = useState(currentStep.answers[0]);

  const handleAnswerChange = useCallback((answer) => {
    setCurrentAnswer(answer);
  }, []);

  const handleAnswer = useCallback(() => {
    if (currentAnswer.info) {
      return;
    }

    if (currentStepNumber === stepsAmount) {
      onEvaluateResultHandler(currentAnswer.points);

      return;
    }

    onNextStepHandler(currentAnswer.points);
  }, [currentAnswer, currentStepNumber, stepsAmount, onNextStepHandler, onEvaluateResultHandler]);

  useEffect(() => {
    setCurrentAnswer(currentStep.answers[0]);
  }, [currentStep]);

  return (
    <div className="investment-quiz-step">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="investment-quiz-step-meta">
            <Image image={currentStep.image[0]} additionalClasses="me-4" />

            <div className="investment-quiz-step-header">
              <div className="investment-quiz-step-count">
                {stepsLabel} {currentStepNumber} / {stepsAmount}
              </div>

              <h5 className="investment-quiz-step-headline">{currentStep.headline}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-medium">
        <div className="col-md-5">
          <Headline headerType="h3" headerStyle="" headline={currentStep.question} />

          {currentStep.teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={currentStep.teaser} />
            </div>
          )}
        </div>

        <div className="col-md-5 mt-4 mt-md-0">
          {currentStep.answers.map((answer) => {
            return (
              <div key={answer.points} className="form-check">
                <input
                  id={answer.points}
                  type="radio"
                  name="answer"
                  value={answer.points}
                  checked={answer.points === currentAnswer.points}
                  className="form-check-input"
                  onChange={() => handleAnswerChange(answer)}
                />

                <label htmlFor={answer.points} className="form-check-label">
                  {answer.answer}
                </label>
              </div>
            );
          })}

          <div
            className="mt-medium"
            role="button"
            tabIndex="0"
            onClick={handleAnswer}
            onKeyDown={() => {}}
          >
            <LinkSwitcher
              linkType="btn-primary"
              link={{ publicUrl: "#", title: currentStep.buttonLabel }}
              additionalClasses={currentAnswer.info ? "disabled" : ""}
            />
          </div>

          {currentAnswer.info && (
            <div className="mt-medium">
              <div className="fs-7">{currentAnswer.info}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardInvestmentQuitStep.propTypes = {
  currentStep: PropTypes.instanceOf(Object),
  currentStepNumber: PropTypes.number,
  stepsAmount: PropTypes.number,
  stepsLabel: PropTypes.string,
  onNextStepHandler: PropTypes.func,
  onEvaluateResultHandler: PropTypes.func,
};

export default StandardInvestmentQuitStep;
