/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardInformationSlider = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    description,
    startValue,
    maxValue,
    thresholds,
    unitType,
    animation,
  },
}) => {
  const [sliderValue, setSliderValue] = useState(startValue);
  const [activeThreshold, setActiveThreshold] = useState(0);

  const onChangeHandler = useCallback(({ target: { value } }) => {
    setSliderValue(value);
  }, []);

  const getActiveThreshold = useCallback(
    (value) => {
      const activeIndex = thresholds.findIndex(
        (threshold) => value >= threshold.startValue && value <= threshold.endValue,
      );

      setActiveThreshold(activeIndex);
    },
    [thresholds, setActiveThreshold],
  );

  useEffect(() => {
    getActiveThreshold(sliderValue);
  }, [sliderValue, getActiveThreshold]);

  return (
    <div className="container information-slider standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {thresholds.length > 0 && (
        <div className="row mt-small">
          <div className="col-12">
            {description && (
              <div className="information-slider-description">
                <Text text={description} />
              </div>
            )}

            <div className="information-slider-value">
              <Headline headerType="h5" headline={`${sliderValue} ${unitType}`} />
            </div>

            <input
              type="range"
              className="form-range information-slider-control"
              min="0"
              max={maxValue}
              value={sliderValue}
              onChange={onChangeHandler}
            />

            <div className="information-slider-result">
              <Text
                text={`${thresholds[activeThreshold].textBefore} ${sliderValue} ${unitType} ${thresholds[activeThreshold].textAfter}`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardInformationSlider.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardInformationSlider;
