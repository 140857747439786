import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { Tab } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { createLineChart } from "../../../Utils/ChartUtils";
import { chartColors } from "../../../Constants/ChartConstants";

const StandardLineChars = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    charts,
    animation,
  },
}) => {
  const tabsRef = useRef();

  const getLines = useCallback(
    (index) => {
      const currentChart = charts[index];

      let chart = {
        index: 0,
        xAxis: "",
        yAxis: "",
      };

      const linesInfo = {
        info: [],
        data: [],
      };

      if (currentChart) {
        chart = {
          index,
          xAxis: currentChart.Xaxis,
          yAxis: currentChart.Yaxis,
        };

        currentChart.lines.forEach((currentLine, lineIndex) => {
          // render 5 series max
          if (lineIndex > 4) {
            return;
          }

          const info = {
            uid: currentLine.uid,
            name: currentLine.legend,
            color: chartColors[lineIndex],
          };

          linesInfo.info.push(info);

          currentLine.values.forEach((currentValue) => {
            const existingValueX = linesInfo.data.find(
              (lineData) => lineData.valueX === currentValue.valueX,
            );

            if (existingValueX) {
              // if an object with such date exists - add a new isin and value there
              existingValueX[currentLine.uid] = currentValue.valueY;
            } else {
              // otherwise - create a new entry
              const newItem = {};

              newItem[currentLine.uid] = currentValue.valueY;
              newItem.valueX = currentValue.valueX;

              linesInfo.data.push(newItem);
            }
          });
        });
      }

      return { chart, linesInfo };
    },
    [charts],
  );

  useEffect(() => {
    const amCharts = [];

    charts.forEach((chart, index) => {
      amCharts.push(
        createLineChart(`chartdiv_${chart.header.replace(/ /g, "_")}_${index}`, getLines(index)),
      );
    });

    // first half of the hack that is needed prevent troubles with legend on tab change
    tabsRef.current.querySelectorAll(".tab-content > div").forEach((el) => {
      el.classList.add("tab-pane");
    });

    tabsRef.current.querySelector(".tab-pane:first-child").classList.add("active");

    if (charts.length > 1) {
      tabsRef.current.querySelector(".nav-link:first-child").classList.add("active");

      tabsRef.current.querySelectorAll(".nav-link").forEach((el) => {
        const tab = new Tab(el);

        el.addEventListener("click", (e) => {
          e.preventDefault();

          // second half of the hack that is needed prevent troubles with legend on tab change
          amCharts.forEach((chart) => {
            chart.deepInvalidate();
            chart.appear();
          });

          tab.show();
        });
      });
    }

    return () => amCharts.forEach((amChart) => amChart.dispose());
  }, [charts, getLines]);

  const renderTabNav = useCallback(() => {
    return (
      charts.length > 1 &&
      charts.map((chart) => {
        return (
          <li
            key={`key_${chart.header.replace(/ /g, "_") || Math.random()}`}
            className="nav-item"
            role="presentation"
          >
            <button
              className="nav-link"
              id={`${chart.header.replace(/ /g, "_")}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#${chart.header.replace(/ /g, "_")}`}
              type="button"
              role="tab"
              aria-controls={chart.header.replace(/ /g, "_")}
            >
              {chart.header}
            </button>
          </li>
        );
      })
    );
  }, [charts]);

  const renderTabContent = useCallback(() => {
    return charts?.map((chart, index) => {
      const chartId = `chartdiv_${chart.header.replace(/ /g, "_")}_${index}`;
      // const legendId = `legenddiv_${chart.header.replace(/ /g, "_")}_${index}`;

      return (
        <div
          key={`key_${chart.header.replace(/ /g, "_") || Math.random()}`}
          id={chart.header.replace(/ /g, "_")}
          role="tabpanel"
          aria-labelledby={`${chart.header.replace(/ /g, "_")}-tab`}
        >
          <div className="chart">
            <div id={chartId} className="line_chart" style={{ height: "600px" }} />
          </div>
        </div>
      );
    });
  }, [charts]);

  return (
    <div className="container line-charts standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
        </div>
      </div>

      {(headerLink || secondaryLink) && (
        <div className="row justify-content-center mt-small" data-aos={animation.links.default}>
          <div className="col-md-10">
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                // additionalClasses="btn-lg rounded-pill"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        </div>
      )}

      {charts.length > 1 && (
        <div className="row mt-xlarge">
          <div className="col-12">
            <div className="tabs" ref={tabsRef}>
              <ul className="nav nav-pills" id="myTab" role="tablist">
                {renderTabNav()}
              </ul>

              <div className="tab-content mt-small">{renderTabContent()}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardLineChars.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardLineChars;
