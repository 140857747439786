import React from "react";
import PropTypes from "prop-types";

import StandardContactInfo from "./StandardContactInfo";
import ComponentMissing from "../../Debug/ComponentMissing";

const ContactInfoSwitcher = ({ layout, contactInfo }) => {
  const properties = {
    contactInfo,
  };

  function switchContactInfo() {
    switch (layout) {
      case "standard":
        return <StandardContactInfo properties={properties} />;
      default:
        return <ComponentMissing type="ContactInfo" subType={layout} />;
    }
  }

  return switchContactInfo();
};

ContactInfoSwitcher.propTypes = {
  layout: PropTypes.string,
  contactInfo: PropTypes.instanceOf(Array),
};

export default ContactInfoSwitcher;
