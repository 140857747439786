import React from "react";
import PropTypes from "prop-types";

import StandardInvestmentQuiz from "./StandardInvestmentQuiz";
import ComponentMissing from "../../Debug/ComponentMissing";

const InvestmentQuizSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  steps,
  evaluation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    steps,
    evaluation,
  };

  function switchInvestmentQuiz() {
    switch (layout) {
      case "standard":
        return <StandardInvestmentQuiz properties={properties} />;
      default:
        return <ComponentMissing type="Investment Quiz" subType={layout} />;
    }
  }

  return switchInvestmentQuiz();
};

InvestmentQuizSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  steps: PropTypes.instanceOf(Array),
  evaluation: PropTypes.instanceOf(Array),
};

export default InvestmentQuizSwitcher;
