import React from "react";
import PropTypes from "prop-types";

import StandardSidebarAd from "./StandardSidebarAd";
import ComponentMissing from "../../Debug/ComponentMissing";

const SidebarAdSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  teaser,
  image,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    image,
  };

  function switchSidebarAd() {
    switch (layout) {
      case "standard":
        return <StandardSidebarAd properties={properties} />;
      default:
        return <ComponentMissing type="SidebarAd" subType={layout} />;
    }
  }

  return switchSidebarAd();
};

SidebarAdSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default SidebarAdSwitcher;
