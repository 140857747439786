import React from "react";
import PropTypes from "prop-types";

const Telephone = ({
  id,
  label,
  formData,
  validationErrors,
  onChangeHandler,
  placeholder,
  additionalClasses,
}) => {
  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      <div className="form-floating">
        <input
          type="tel"
          id={id}
          name={id}
          placeholder={placeholder || label}
          value={formData[id]}
          onChange={onChangeHandler}
          className={`form-control color-static ${validationErrors ? "is-invalid" : ""}`.trim()}
        />

        {(label || placeholder) && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            htmlFor={id}
            className="form-label color-static"
            dangerouslySetInnerHTML={{ __html: label || placeholder }}
          />
        )}
      </div>
    </div>
  );
};

Telephone.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  validationErrors: PropTypes.string,
  onChangeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  additionalClasses: PropTypes.string,
};

Telephone.defaultProps = {
  additionalClasses: "",
};

export default Telephone;
