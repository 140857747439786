import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import TranslationContext from "../../../Context/Translation";

import WallNewsListItem from "./WallNewsListItem";

const WallNewsList = ({ properties: { news, columnsPerRow, hideDate, animation } }) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderNews = useCallback(() => {
    return news.map((newsItem) => {
      return (
        <WallNewsListItem
          news={newsItem}
          key={`key_${newsItem.title || Math.random()}`}
          linkType={newsItem.linkType}
          linkTitle={
            newsItem.linkTitle ||
            (activeTranslation.twoLetterIsoCode === "de" ? "Weiterlesen" : "Read More")
          }
          hideDate={hideDate}
          animation={animation}
        />
      );
    });
  }, [news, activeTranslation, hideDate, animation]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container news-list wall">
      {news.length > 0 && (
        <div
          className={`row 
                      row-cols-${colXs} 
                      row-cols-sm-${colSm} 
                      row-cols-md-${colMd} 
                      row-cols-lg-${colLg} 
                      g-4`.replace(/\n\s+/g, "")}
        >
          {renderNews()}
        </div>
      )}
    </div>
  );
};

WallNewsList.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default WallNewsList;
