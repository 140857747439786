import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const StandardBreadcrumbs = ({ properties: { breadcrumbs } }) => {
  return (
    <div className="container breadcrumbs standard">
      <div className="row">
        <div className="col-12">
          {breadcrumbs.length > 0 && (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {breadcrumbs.map((breadcrumb) => {
                  if (breadcrumb.current === 1) {
                    return (
                      <li key={breadcrumb.title} className="breadcrumb-item active">
                        {breadcrumb.title}
                      </li>
                    );
                  }

                  return (
                    <li key={breadcrumb.title} className="breadcrumb-item">
                      <NavLink to={breadcrumb.link}>{breadcrumb.title}</NavLink>
                    </li>
                  );
                })}
              </ol>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

StandardBreadcrumbs.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardBreadcrumbs;
