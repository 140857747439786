import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Headline from "../../Atoms/Headline";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardInvestmentQuitStep from "./StandardInvestmentQuitStep";

import TranslationContext from "../../../Context/Translation";

const StandardInvestmentQuiz = ({
  properties: { header, headerType, headerDisplayStyle, steps, evaluation },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const [points, setPoints] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line max-len
    let navBarHeight = 80; // on the first load we won't be able to queue the navbar and get its height, because it will be loaded after the page

    const navBar = document.querySelector("#navigation .navbar");

    if (navBar) {
      navBarHeight = parseInt(window.getComputedStyle(navBar).getPropertyValue("height"), 10);
    }

    let contentPadding = 0;

    const content = document.querySelector("#main .container-fluid");

    if (content) {
      contentPadding = parseInt(
        window.getComputedStyle(content).getPropertyValue("padding-top"),
        10,
      );
    }

    const handleViewportChange = () => {
      const viweportHeight = window.innerHeight;

      const contentHeight = viweportHeight - navBarHeight - contentPadding;

      setMinHeight(contentHeight);
    };

    handleViewportChange();

    window.addEventListener("resize", handleViewportChange);

    return () => {
      window.removeEventListener("resize", handleViewportChange);
    };
  }, []);

  const history = useHistory();

  const restartQuiz = useCallback(() => {
    setPoints(0);
    setCurrentStep(0);
  }, []);

  const onNextStepHandler = useCallback(
    (value) => {
      setPoints(points + +value);
      setCurrentStep(currentStep + 1);
    },
    [points, currentStep],
  );

  const onEvaluateResultHandler = useCallback(
    (value) => {
      const result = evaluation.find((ev) => {
        return points + +value >= ev.points_min && points + +value <= ev.points_max;
      });

      if (result) {
        history.push(result.link.url);

        return;
      }

      restartQuiz();
    },
    [history, points, evaluation, restartQuiz],
  );

  return (
    <div className="investment-quiz-wrapper" style={{ minHeight }}>
      <div className="container investment-quiz standard">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
          </div>
        </div>

        {steps.length > 0 && (
          <div className="row mt-small">
            <StandardInvestmentQuitStep
              currentStep={steps[currentStep]}
              currentStepNumber={currentStep + 1}
              stepsAmount={steps.length}
              stepsLabel={activeTranslation.twoLetterIsoCode === "de" ? "Schritt" : "Step"}
              onNextStepHandler={onNextStepHandler}
              onEvaluateResultHandler={onEvaluateResultHandler}
            />
          </div>
        )}
      </div>

      {currentStep === 0 && <div className="row mt-small" />}

      {currentStep > 0 && (
        <div className="row mt-small pt-medium pb-medium bg_color_main">
          <div className="col d-flex justify-content-center">
            <div role="button" tabIndex="0" onClick={restartQuiz} onKeyDown={() => {}}>
              <LinkSwitcher
                linkType="link"
                link={{
                  publicUrl: "#",
                  title:
                    activeTranslation.twoLetterIsoCode === "de" ? "Erneut Starten" : "Start Again",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardInvestmentQuiz.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardInvestmentQuiz;
