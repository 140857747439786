import React from "react";
import PropTypes from "prop-types";

import StandardContactInfoItem from "./StandardContactInfoItem";

const StandardContactInfo = ({ properties: { contactInfo } }) => {
  return (
    <div className="contact-info">
      {contactInfo.map((infoItem, index) => {
        return (
          <StandardContactInfoItem
            index={index}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            infoItem={infoItem}
            contactInfoLength={contactInfo.length}
          />
        );
      })}
    </div>
  );
};

StandardContactInfo.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardContactInfo;
