import React from "react";
import PropTypes from "prop-types";

import Text from "../../Atoms/Text";

const StandardCustomCode = ({ properties: { code } }) => {
  return (
    <div className="custom-code standard">
      <Text textType="html" text={code} />
    </div>
  );
};

StandardCustomCode.propTypes = {
  properties: PropTypes.instanceOf(Object),
};
export default StandardCustomCode;
