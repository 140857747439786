import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import SimpleProductCardsItem from "./SimpleProductCardsItem";

const SimpleProductCards = ({
  properties: { header, headerType, headerDisplayStyle, subline, teaser, productCards, animation },
}) => {
  const renderProductCards = useCallback(() => {
    return productCards.map((productCard) => {
      return (
        <SimpleProductCardsItem
          productCard={productCard}
          key={`key_${productCard.header || Math.random()}`}
          animation={animation}
        />
      );
    });
  }, [productCards, animation]);

  return (
    <div className="container product-cards simple">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
        </div>
      </div>

      {productCards.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center g-4">{renderProductCards()}</div>
        </div>
      )}
    </div>
  );
};

SimpleProductCards.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SimpleProductCards;
