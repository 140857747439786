import React from "react";
import PropTypes from "prop-types";

import StandardInvestmentCalculator from "./StandardInvestmentCalculator";
import ComponentMissing from "../../Debug/ComponentMissing";

const InvestmentCalculatorSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  calculatorOptions,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    calculatorOptions,
  };

  function switchInvestmentCalculator() {
    switch (layout) {
      case "standard":
        return <StandardInvestmentCalculator properties={properties} />;
      default:
        return <ComponentMissing type="Investment Calculator" subType={layout} />;
    }
  }

  return switchInvestmentCalculator();
};

InvestmentCalculatorSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  calculatorOptions: PropTypes.instanceOf(Array),
};

export default InvestmentCalculatorSwitcher;
