import React from "react";
import PropTypes from "prop-types";

import DefaultFormHandler from "./DefaultFormHandler";
import ComponentMissing from "../../Debug/ComponentMissing";

const FormFrameworkSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  teaser,
  form,
  uplink,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    form,
    uplink,
    animation,
  };

  const switchForm = () => {
    // currently i route all form to one resolver, different layouts are only
    // here when i cant handle it with magical functions
    switch (layout) {
      case "standard":
      case "contact":
      case "newsletter":
        return <DefaultFormHandler properties={properties} />;
      default:
        return <ComponentMissing type="FormFramework" subType={layout} />;
    }
  };

  return switchForm();
};

FormFrameworkSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  uplink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  animation: PropTypes.instanceOf(Object),
};

export default FormFrameworkSwitcher;
