import React from "react";
import PropTypes from "prop-types";

const RadioButton = ({ id, label, formData, onChangeHandler, options, additionalClasses }) => {
  return (
    <div className={`col-12 ${additionalClasses}`.trim()}>
      <fieldset>
        {label && <legend>{label}</legend>}

        {Object.values(options).map((option) => {
          return (
            <div key={`key_${option || Math.random()}`} className="form-check form-check-inline">
              <input
                type="radio"
                id={option}
                name={id}
                value={option}
                checked={formData[id] === option}
                onChange={onChangeHandler}
                className="form-check-input"
              />

              {option && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label
                  htmlFor={option}
                  className="form-check-label"
                  dangerouslySetInnerHTML={{ __html: option }}
                />
              )}
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  onChangeHandler: PropTypes.func,
  options: PropTypes.instanceOf(Object),
  additionalClasses: PropTypes.string,
};

RadioButton.defaultProps = {
  additionalClasses: "",
};

export default RadioButton;
