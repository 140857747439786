import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { ReactComponent as ScrollToTopIcon } from "../../Images/svg/chevron-up.svg";

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onScroll = () => {
      return window.pageYOffset ? setVisible(true) : setVisible(false);
    };

    // capture: true to fire only when window itself is beign scrolled, and not
    // some element inside (event bubbling)
    window.addEventListener("scroll", onScroll, { capture: false });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top ${visible ? "visible" : ""}`.trim()}
      type="button"
      onClick={scrollToTop}
    >
      <div className="scroll-to-top-icon">
        <ScrollToTopIcon />
      </div>
    </button>
  );
};

ScrollToTop.propTypes = {};

export default ScrollToTop;
