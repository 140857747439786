import React from "react";
import PropTypes from "prop-types";

const Honeypot = ({ id, formData, onChangeHandler }) => {
  const style = {
    position: "absolute",
    margin: "0 0 0 -999em",
  };

  return (
    <input
      style={style}
      id={id}
      type="text"
      name={id}
      value={formData[id] || ""}
      onChange={onChangeHandler}
    />
  );
};

Honeypot.propTypes = {
  id: PropTypes.string,
  formData: PropTypes.instanceOf(Object),
  onChangeHandler: PropTypes.func,
};

export default Honeypot;
