import React from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

import { ReactComponent as LocationIcon } from "../../../Images/svg/location.svg";
import { ReactComponent as MailIcon } from "../../../Images/svg/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../Images/svg/phone.svg";

const StandardContactCard = ({
  properties: { header, headerType, headerDisplayStyle, subline, image, contactInfo, animation },
}) => {
  const {
    contactName,
    contactPosition,
    contactLocation,
    contactMail,
    contactPhone,
    contactDetails,
  } = contactInfo;

  return (
    <div className="container contact-card standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {(contactName ||
        contactPosition ||
        contactLocation ||
        contactPhone ||
        contactMail ||
        contactDetails.length > 0) && (
        <div className="mt-small">
          <div className="card">
            <Image image={image} animation={animation.image.contactCard} />

            <div className="card-body">
              <div className="contact-card-info">
                <Headline headerType="h3" headline={contactName} additionalClasses="color-static" />

                <Text text={contactPosition} additionalClasses="color-static" />

                {(contactLocation || contactPhone || contactMail || contactDetails.length > 0) && (
                  <div
                    className="mt-medium"
                    data-aos={animation.links.default}
                    data-aos-delay="500"
                  >
                    {contactLocation && (
                      <div className="contact-card-info-item">
                        <LocationIcon />

                        {contactLocation}
                      </div>
                    )}

                    {contactMail && (
                      <div className="contact-card-info-item">
                        <MailIcon />

                        <a href={`mailto:${contactMail}`}>{contactMail}</a>
                      </div>
                    )}

                    {contactPhone && (
                      <div className="contact-card-info-item">
                        <PhoneIcon />

                        <a href={`tel:${contactPhone.replace(/\D/g, "")}`}>{contactPhone}</a>
                      </div>
                    )}

                    {contactDetails.length > 0 && (
                      <ul className="contact-card-additional">
                        {contactDetails.map((contact) => (
                          <li key={contact} className="contact-card-additional-item">
                            {contact}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardContactCard.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardContactCard;
