import React from "react";
import PropTypes from "prop-types";

import WallGallery from "./WallGallery";
import CarouselGallery from "./CarouselGallery";
import SliderGallery from "./SliderGallery";
import CardsSliderGallery from "./CardsSliderGallery";
import CenteredCardsSliderGallery from "./CenteredCardsSliderGallery";
import ComponentMissing from "../../Debug/ComponentMissing";

const GallerySwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  id,
  galleryItems,
  columnsPerRow,
  grayscale,
  lightbox,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    galleryItems,
    columnsPerRow,
    grayscale,
    lightbox,
    animation,
  };

  function switchGallery() {
    switch (layout) {
      case "wall":
        return <WallGallery properties={properties} />;
      case "carousel":
        return <CarouselGallery properties={properties} />;
      case "slider":
        return <SliderGallery properties={properties} />;
      case "cards-slider":
        return <CardsSliderGallery properties={properties} />;
      case "centered-cards-slider":
        return <CenteredCardsSliderGallery properties={properties} />;
      default:
        return <ComponentMissing type="Gallery" subType={layout} />;
    }
  }

  return switchGallery();
};

GallerySwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  subline: PropTypes.string,
  id: PropTypes.number,
  items: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  columnsPerRow: PropTypes.instanceOf(Object),
  grayscale: PropTypes.bool,
  lightbox: PropTypes.bool,
  animation: PropTypes.instanceOf(Object),
};

export default GallerySwitcher;
