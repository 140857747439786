import React from "react";
import PropTypes from "prop-types";

import StandardTextAndContact from "./StandardTextAndContact";
import LargeTextAndContact from "./LargeTextAndContact";
import ComponentMissing from "../../Debug/ComponentMissing";

const TextAndContactSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  image,
  companyName,
  contactName,
  contactPosition,
  mirrored,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    image,
    companyName,
    contactName,
    contactPosition,
    mirrored,
    animation,
  };

  function switchTextAndContact() {
    switch (layout) {
      case "standard":
        return <StandardTextAndContact properties={properties} />;
      case "large":
        return <LargeTextAndContact properties={properties} />;
      default:
        return <ComponentMissing type="TextAndContact" subType={layout} />;
    }
  }

  return switchTextAndContact();
};

TextAndContactSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  companyName: PropTypes.string,
  contactName: PropTypes.string,
  contactPosition: PropTypes.string,
  mirrored: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  animation: PropTypes.instanceOf(Object),
};

export default TextAndContactSwitcher;
