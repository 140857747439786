import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as ArrowRight } from "../../../Images/svg/arrow-right.svg";

import { generateId } from "../../../Utils/FunctionalUtils";

const VerticalListMenuSection = ({ properties: { navigation } }) => {
  const [active, setActive] = useState();
  const history = useHistory();

  const renderNavigation = () => {
    return navigation?.map((navigationItem) => {
      const anchorLink = `#${generateId(navigationItem)}`;

      return (
        navigationItem.tx_ahoii_toolkit_section_name && (
          <li key={`key_${anchorLink}`} className="nav-item">
            <div className="nav-link-icon">
              <ArrowRight />
            </div>
            <a
              className={`nav-link ${anchorLink === active ? "active" : ""}`.trim()}
              href={anchorLink}
              onClick={(e) => {
                e.preventDefault();

                history.push(anchorLink);

                setActive(anchorLink);
              }}
            >
              <span>{navigationItem.tx_ahoii_toolkit_section_name}</span>
            </a>
          </li>
        )
      );
    });
  };

  return (
    <div className="container menu-section vertical-list">
      <div className="card">
        <div className="card-body">
          <ul className="nav">{renderNavigation()}</ul>
        </div>
      </div>
    </div>
  );
};

VerticalListMenuSection.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default VerticalListMenuSection;
