/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import Headline from "../../Atoms/Headline";

import { ReactComponent as LocationIcon } from "../../../Images/svg/map-location.svg";
import { ReactComponent as PhoneIcon } from "../../../Images/svg/map-phone.svg";
import { ReactComponent as FaxIcon } from "../../../Images/svg/map-fax.svg";
import { ReactComponent as MailIcon } from "../../../Images/svg/map-mail.svg";
import { ReactComponent as LinkIcon } from "../../../Images/svg/map-link.svg";

/**
 * full size component
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GoogleMapComponent = withScriptjs(
  withGoogleMap(
    ({ styleOptions, properties: { markers, icon, centerLat, centerLng, defaultZoom } }) => {
      let defaultCenter = { lat: -34.397, lng: 150.644 };

      if (centerLat && centerLng) {
        defaultCenter = { lat: +centerLat, lng: +centerLng };
      }

      let markerIcon = null;

      if (icon) {
        markerIcon = { url: icon.publicUrl };
      }

      const renderMarkers = () => {
        return markers.map((marker) => {
          return (
            <Marker
              key={`key_${marker.latitude}_${marker.longitude}`}
              icon={markerIcon}
              position={{ lat: +marker.latitude, lng: +marker.longitude }}
            />
          );
        });
      };

      return (
        <GoogleMap
          defaultZoom={+defaultZoom}
          defaultCenter={defaultCenter}
          defaultOptions={{ styles: styleOptions }}
        >
          {renderMarkers()}
        </GoogleMap>
      );
    },
  ),
);

const StandardGoogleMapsAndContacts = (props) => {
  const {
    mirrored,
    contactTitle,
    contactAddress,
    contactPhone,
    contactPhoneAlt,
    contactEmail,
    contactLink,
    markers,
  } = props.properties;

  const { latitude, longitude } = markers[0];

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-12 col-md-8 col-lg-6 col-xl-4">
        <div className="card">
          <div className="card-body">
            <Headline headerType="h3" headline={contactTitle} />

            <ul className="contact-list">
              <li className="contact-list-item">
                <LocationIcon />

                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                  target="_blank"
                  rel="noreferrer"
                  className="contact-list-link"
                >
                  {contactAddress}
                </a>
              </li>
              <li className="contact-list-item">
                <PhoneIcon />

                <a
                  href={`tel:${contactPhone.replace("/(0)|s+/", "")}`}
                  target="_blank"
                  rel="noreferrer"
                  className="contact-list-link"
                >
                  {contactPhone}
                </a>
              </li>
              <li className="contact-list-item">
                <FaxIcon />

                <a
                  href={`tel:${contactPhoneAlt.replace("/(0)|s+/", "")}`}
                  target="_blank"
                  rel="noreferrer"
                  className="contact-list-link"
                >
                  {contactPhoneAlt}
                </a>
              </li>
              <li className="contact-list-item">
                <MailIcon />

                <a
                  href={`mailto:${contactEmail}`}
                  target="_blank"
                  rel="noreferrer"
                  className="contact-list-link"
                >
                  {contactEmail}
                </a>
              </li>

              <li className="contact-list-item">
                <LinkIcon />

                <a
                  href={contactLink.url}
                  target="_blank"
                  rel="noreferrer"
                  className="contact-list-link"
                >
                  {contactLink.title}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }, [
    contactTitle,
    contactAddress,
    contactPhone,
    contactPhoneAlt,
    contactEmail,
    contactLink,
    latitude,
    longitude,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className="row flex-row-reverse align-items-center mirrored">{getTextContainer()}</div>
      );
    }

    return <div className="row align-items-center">{getTextContainer()}</div>;
  }, [mirrored, getTextContainer]);

  return (
    <div className="google-maps-and-contacts standard">
      <div className="row">
        <div className="col-12">
          <GoogleMapComponent {...props} />

          <div className="container">{getContent()}</div>
        </div>
      </div>
    </div>
  );
};
export default StandardGoogleMapsAndContacts;
